export const COOPERATION_MODELS_LIST = [
  {
    id: 1,
    name: 'about.cooperationModels.item_1',
    img: '/images/cooperationModels/item-1.svg',
  },
  {
    id: 2,
    name: 'about.cooperationModels.item_2',
    img: '/images/cooperationModels/item-2.svg',
  },
  {
    id: 3,
    name: 'about.cooperationModels.item_3',
    img: '/images/cooperationModels/item-3.svg',
  },
];
