import React, { useEffect } from 'react';
import styles from './styles.module.css';

export const TermOfUse = () => {
  return (
    <div>
      <main>
        <div className={styles.tou}>
          <h1 class="tou__title">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
          <h1 class="tou-sub__title">1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</h1>
          <p class="tou__text">В данном Соглашении термины имеют следующее значение:</p>
          <p class="tou__text">«Сайт» — это ресурс по адресу: <a href='/'>www.evasoft.org</a> (включая поддомены), который принадлежит Компании и доступен по следующему адресу: <a href='/'>www.evasoft.org</a> (вместе с поддоменами).</p>
          <p class="tou__text">«Пользователь», «Вы», «Ваш», «Вас», «Вами» или аналогичные формы (в зависимости от контекста) обозначают лицо, которое:</p>
          <ol class="tou__list">
            <li>использует Сайт и/или получает доступ к его Контенту;</li>
            <li>согласилось соблюдать правила использования Сайта, изложенные в настоящем Соглашении, при использовании Сайта.</li>
          </ol>
          <p class="tou__text">«Компания», «Мы», «Наш», «Нас», «Нами» или аналогичные формы (в зависимости от контекста) обозначают: ООО "ЭВАСОФТ"</p>
          <p class="tou__text">«Контент сайта» включает все материалы, размещенные Компанией и/или третьими лицами (с разрешения Компании) на Сайте, такие как элементы дизайна, текст, графические изображения, иллюстрации, видео, программы, музыка, звуки, информация, уведомления и другие объекты аналогичного назначения, их подборки и комбинации, пользовательские интерфейсы, визуальные интерфейсы, товарные знаки, логотипы, программное обеспечение, базы данных, а также дизайн, структуру, выбор, координацию, внешний вид, стиль и размещение этих материалов на Сайте.</p>
          <p class="tou__text">«ПО сайта» означает программное обеспечение, разработанное Компанией (или третьими лицами по поручению Компании) для Сайта, включая, но не ограничиваясь, софт, скрипты, коды (HTML-коды), программы и подобные элементы.</p>

          <h1 class="tou-sub__title">2. ПРИСОЕДИНЕНИЕ К СОГЛАШЕНИЮ</h1>
          <p class="tou__text">2.1. Настоящее Пользовательское соглашение (далее — «Соглашение») определяет правила и порядок использования Сайта, права и обязанности Пользователей, а также регулирует порядок получения Пользователем доступа к Сайту.</p>
          <p class="tou__text">2.2. Пользователь принимает условия данного Соглашения, продолжая использовать Сайт.
          </p>
          <p class="tou__text">2.3. Соглашение является обязательным для сторон (т.е. для Компании и Пользователя). Уступка Пользователем своих прав по данному Соглашению возможна только с предварительного письменного согласия Компании.</p>
          <p class="tou__text">2.4. Пользователь имеет право:</p>
          <ol class="tou__list">
            <li>Использовать Сайт исключительно в целях и порядке, предусмотренных Соглашением и не противоречащих законодательству Российской Федерации.</li>
            <li>Копировать информацию с Сайта с указанием источника и с согласия Компании.</li>
            <li>ИИспользовать информацию Сайта для личных некоммерческих целей.</li>
          </ol>
          <p class="tou__text">2.5. Пользователь обязуется:</p>
          <ol class="tou__list">
            <li>Соблюдать имущественные и неимущественные права авторов и других правообладателей при использовании Сайта.</li>
            <li>Не предпринимать действия, которые могут нарушить нормальное функционирование Сайта.</li>
            <li>Не распространять через Сайт конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических или юридических лицах.</li>
            <li>Избегать действий, которые могут нарушить конфиденциальность охраняемой законодательством информации.
            </li>
            <li>Не использовать Сайт для распространения рекламной информации без согласия Компании.</li>
            <li>Не использовать устройства, программы, процедуры, алгоритмы, автоматизированные или ручные процессы для доступа к содержимому Сайта, его копирования или отслеживания.
            </li>
            <li>Не обходить навигационную структуру Сайта для получения информации, которая не предоставлена сервисами Сайта.
            </li>
            <li>Не осуществлять несанкционированный доступ к функциям Сайта, системам или сетям, связанным с ним.
            </li>
            <li>Не нарушать систему безопасности или аутентификации на Сайте.</li>
            <li>Не выполнять обратный поиск или попытки отслеживания информации о других Пользователях Сайта.
            </li>
            <li>Не использовать Сайт для любых целей, запрещенных законодательством Российской Федерации, и не подстрекать к незаконной деятельности или нарушению прав Компании или третьих лиц.
            </li>
          </ol>

          <h1 class="tou-sub__title">3. ПОЛЬЗОВАТЕЛИ САЙТА</h1>
          <h1 class="tou-sub__title">Общие критерии и возраст</h1>
          <p class="tou__text">3.1. Для использования Сайта Пользователи должны соответствовать следующим критериям:
          </p>
          <ol class="tou__list">
            <li>Быть не младше 14 лет;</li>
            <li>Не быть ограниченным в праве доступа к Сайту на основании судебного решения, вступившего в законную силу, или в случаях, предусмотренных действующим законодательством или условиями данного Соглашения.
            </li>
          </ol>

          <h1 class="tou-sub__title">4. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h1>
          <p class="tou__text">4.1. Все права собственности, включая имущественные права интеллектуальной собственности, на Контент сайта и ПО сайта принадлежат Компании. ПО сайта и Контент сайта защищены авторским правом в соответствии с законодательством Российской Федерации и международными договорами по защите интеллектуальной собственности.
          </p>
          <p class="tou__text">4.2. Пользователям запрещается копировать, воспроизводить, модифицировать, компилировать, распространять, отображать, публиковать, скачивать, передавать, продавать или каким-либо образом использовать Контент сайта и ПО сайта, кроме случаев, предусмотренных данным Соглашением или действующим законодательством Российской Федерации.
          </p>
          <p class="tou__text">4.3. Ничто в данном Соглашении не может быть истолковано как передача Пользователю исключительных прав на Контент сайта или ПО сайта.</p>
          <p class="tou__text">4.4. Компания владеет всеми правами на торговые марки, коммерческие наименования, бренды, логотипы, зарегистрированные на ее имя. Эти торговые марки защищены законодательством, и ничто в данном Соглашении не может быть истолковано как предоставление лицензии на использование таких торговых марок.
          </p>
          <h1 class="tou-sub__title">5. ПОРЯДОК РАБОТЫ С САЙТОМ</h1>
          <p class="tou__text"><b>Правила поведения на сайте</b></p>
          <p class="tou__text">5.1. При использовании Сайта Пользователь обязуется соблюдать следующие правила:</p>
          <ol class="tou__list">
            <li>Выполнять все обязательства, взятые на себя при присоединении к данному Соглашению.</li>
            <li>Предоставлять достоверные данные при заполнении форм на Сайте.
            </li>
            <li>Не выдавать себя за другое лицо и не предоставлять данные третьих лиц без их предварительного согласия.
            </li>
            <li>Не загружать, не хранить, не публиковать, не распространять, не размещать иным образом не использовать Контент, который:</li>
            <ul class="tou__list">
              <li>Содержит угрозы, оскорбления, порочит честь и достоинство или нарушает неприкосновенность частной жизни других Пользователей или третьих лиц;
              </li>
              <li>Является спамом, буллингом, вульгарным или непристойным, содержит порнографические материалы или сцены насилия;
              </li>
              <li>Содержит призывы к суициду, разжигание ненависти или экстремизм;
              </li>
              <li>Пропагандирует нарушение прав других лиц или преступные действия;
              </li>
              <li>Нарушает правила данного Соглашения или законодательство.
              </li>
            </ul>
            <li>Не предпринимать действия, направленные на подрыв работы Сайта, включая загрузку вирусов или вредоносного кода, нарушающих функционирование Сайта.
            </li>
            <li>Не совершать незаконные, мошеннические или дискриминационные действия.
            </li>
          </ol>

          <h1 class="tou-sub__title">6. РАЗМЕЩЕНИЕ РЕКЛАМЫ НА САЙТЕ</h1>
          <p class="tou__text"><b>Размещение рекламы Компанией</b></p>
          <p class="tou__text">6.1. Компания может время от времени размещать рекламные или маркетинговые материалы на Сайте.</p>
          <p class="tou__text"><b>Размещение рекламы третьими лицами</b></p>
          <p class="tou__text">6.2. Контент сайта может содержать ссылки на сайты третьих лиц и рекламные материалы, предоставленные ими. Компания не несет ответственности за содержание такой рекламы и качество продвигаемых товаров/услуг.
          </p>
          <p class="tou__text">6.3. Переход на сайты третьих лиц по рекламным ссылкам не гарантирует безопасность таких сайтов. Компания не отвечает за безопасность и использование товаров/услуг, рекламируемых третьими лицами.
          </p>

          <h1 class="tou-sub__title">7. ПРЕКРАЩЕНИЕ ДОСТУПА К САЙТУ</h1>
          <p class="tou__text">7.1. Компания имеет право без предварительного уведомления прекратить доступ Пользователя к Сайту в случае:</p>
          <ol class="tou__list">
            <li>Нарушения условий Соглашения;</li>
            <li>Нарушения прав интеллектуальной собственности Компании или третьих лиц;</li>
            <li>Противозаконных действий или действий, подрывающих работу Сайта;</li>
            <li>Потенциальной юридической ответственности Компании;</li>
            <li>Требований законодательства Российской Федерации.</li>
          </ol>
          <p class="tou__text">7.2. Компания не несет ответственности за ущерб, причиненный невозможностью доступа к Сайту.</p>

          <h1 class="tou-sub__title">8. ЗАДАТЬ ВОПРОС</h1>
          <p class="tou__text">8.1. Для вопросов по условиям Соглашения обращайтесь на электронную почту <a
            class="tou__mail" href="mailto:info@evasoft.org">info@evasoft.org</a>.
          </p>
          <p class="tou__text">8.2. Компания предпримет все усилия для ответа на запрос в разумный срок.</p>

          <h1 class="tou-sub__title">9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h1>
          <p class="tou__text">9.1. Соглашение вступает в силу с момента публикации на Сайте и действует неопределенный срок.</p>
          <p class="tou__text">9.2. Компания может изменять условия Соглашения без уведомления Пользователей. Пользователь несет ответственность за проверку изменений.</p>
          <p class="tou__text">9.3. К условиям Соглашения применяется законодательство Российской Федерации.</p>
          <p class="tou__text">9.4. Сайт предоставляется «как есть». Компания не гарантирует соответствие Сайта Вашим потребностям и не обещает конкретных результатов.</p>
          <p class="tou__text">9.5. При использовании материалов сайта необходима активная гиперссылка на <a
            class="tou__site" href="/">www.evasoft.org</a>, без скрытых ссылок.</p>
          <p class="tou__text">9.6. Пользователю доступна Политика конфиденциальности по адресу
            <a href="/privacy-policy/" class="tou__site">www.evasoft.org/privacy-policy</a>.
          </p>
          <p class="tou__text">9.7. Компания не несет ответственности за:</p>
          <ol class="tou__list">
            <li>Задержки или сбои в операции из-за форс-мажора или неисправностей в системах.</li>
            <li>Неправильное функционирование Сайта при отсутствии у Пользователя необходимого оборудования.
            </li>
            <li>Раскрытие информации о Пользователе, если это требует законодательство или для расследования нарушений.
            </li>
          </ol>

          <h1 class="tou-sub__title">10. РАЗРЕШЕНИЕ СПОРОВ</h1>
          <p class="tou__text">10.1. Перед обращением в суд необходимо предъявить претензию.</p>
          <p class="tou__text">10.2. Получатель претензии должен уведомить о результатах рассмотрения в течение 30
            дней.
          </p>
          <p class="tou__text">10.3. В случае неразрешенности спора добровольно, стороны могут обратиться в суд по месту нахождения Компании.</p>
        </div>
      </main>
    </div>
  );
};
