import React, { useEffect } from 'react';
import { ProjectButtons } from '../../ProjectButtons';

export const Bison = () => {

  useEffect(() => {
    import('../assets/js/additional.min.js')
      .then(() => import('../assets/js/main.min.js')
        .then(() => import('../assets/js/maxline.min.js')))
  }, [])

  return (
    <div id="root">
      <div className="content">
        <div className="portfolio-slider">
          <div className="item">
            <img src="/img/background/bison.jpg" alt="" />
            <div className="inner-text">
              <div className="border">
                <img className=" viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/bison.png" alt="BisonBC" />
                <h1 className=" viewport_animation" data-vp-add-class="animated fadeInRight">Спортсбук Bison</h1>
                <p className=" viewport_animation" data-vp-add-class="animated fadeInRight">Платформа для ставок на
                  спорт в режиме online</p>
              </div>
            </div>
          </div>
        </div>
        <canvas id="maxline-chart" height="400" style={{ display: 'none' }}></canvas>
        <div className="portfolio" id="portfolio">
          <div className="conf-screen bison-background">
            <img className="left viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/bison/screen_2.png" alt="Левый" />
            <img className="center viewport_animation" data-vp-add-class="animated fadeInUp" src="/img/bison/screen_1.png" alt="Центральный" />
            <img className="right viewport_animation" data-vp-add-class="animated fadeInRight" src="/img/bison/screen_3.png" alt="Правый" />
          </div>

          <div className="task bison-background bison-task">
            <div className="container">
              <span className="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Задача</span>
              <h2 className="viewport_animation" data-vp-add-class="animated fadeInUp">Создание самого современного и
                удобного сервиса услуг онлайн беттинга на территории Республики Беларусь</h2>
              <p className="viewport_animation" data-vp-add-class="animated fadeInUp">Bison - готовое и удобное решение
                для операторов iGaming. Для его разработки наша команда уделила много времени изучению ниши
                гэмблинг-бизнеса. Именно поэтому спортсбук Bison имеет широкий спектр возможностей, способный
                удовлетворить потребности клиента в полной степени.

                В Bison проработан отдельно выделенный backoffice, с помощью которого букмекеры и менеджеры могут не
                только управлять данными на сайте, но также работать с бонусными механиками и электронной
                статистикой.

              </p>
            </div>
          </div>
          <div className="bison-line">
            <div className="container inf-cnt">
              <div className="column">
                <span className="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  — Разработка
                  интерфейса</span>
                <h2 className="viewport_animation" data-vp-add-class="animated fadeInUp">Удобная и понятная навигация
                </h2>
                <p className="viewport_animation" data-vp-add-class="animated fadeInUp">При анализе ниши букмекерских
                  компаний Беларуси, мы столкнулись с достаточно посредственным отношением к удобству сервиса в
                  рамках современных тенденций дизайна и юзабилити. По сложившейся традиции, среди букмекерских
                  компаний бытует мнение о том, что интерфейс портала должен максимально повторять все
                  предшествующие варианты и не нести ничего нового, поскольку это якобы может отпугнуть
                  «старичков», которые своими действиями на портале закладывают фундамент финансовой успешности
                  проекта. Нас эта точка зрения совершенно не устраивала и мы решили освежить, покрытую пылью
                  схему взаимоотношений между букмекером и игроком, внеся свежие идеи в интерфейс.

                  Перерабатывая алгоритмы поведения будущих пользоватей, мы старались подготовить площадку,
                  которая полностью смогла бы закрыть текущие потребности каждого отдельно взятого игрока.

                  В дизайне мы максимально сосредоточились на аспектах, про которые совсем забыли букмекерские
                  компании, открывшиеся в начале двухтысячных, а именно – на удобном интерфейсе.

                  Результат работы команды дизайнеров и специалистов по юзабилити был высоко оценен ключевой
                  группой тестировщиков, а так же данными онлайн статистики установленной на сайте.</p>
              </div>
            </div>
            <div className="images">
              <img src="/img/bison/line_1.png" className="images_1" alt="" />
              <img src="/img/bison/line_2.png" className="images_2" alt="" />
              <img src="/img/bison/line_3.png" className="images_3" alt="" />
              <img src="/img/bison/line_4.png" className="images_4" alt="" />
            </div>
          </div>
          <div className="bison-live">
            <div className="container inf-cnt">
              <span className="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Мобильная версия и
                приложение</span>
              <h2 className="viewport_animation" data-vp-add-class="animated fadeInUp">Для максимального охвата аудитории
                была разработана мобильная версия портала</h2>
              <p className="viewport_animation" data-vp-add-class="animated fadeInUp">Следуя требованиям современного
                рынка, мы не могли пройти стороной мимо адаптации букмекерского портала под мобильные устройства. В
                ходе обсуждения данной ситуации было решено реализовать отдельную мобильную версию, а также создать
                мобильное приложение для операционной системы Android.

                И в первом, и во втором способе реализации нам удалось сохранить максимальный набор функциональных
                возможностей, при этом нагрузка на устройство осталась в пределах допустимой нормы для комфортного
                использования. После реализации данного функционала,правильность реализации подтвердилась данными
                статистики поведения пользователей, судя по которым, преобладающая часть пользователей, а именно
                67%, пользуются мобильной версией или приложением.</p>
            </div>
          </div>
          <div className="bison-personal -gray">
            <div className="container inf-cnt">
              <div className="column">
                <span className="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  — Личный кабинет
                  и поддержка</span>
                <h2 className="viewport_animation" data-vp-add-class="animated fadeInUp">Удобный анализ бизнес-модели
                  портала, различные метрики, графики и KPI – всё это в одной панели управления</h2>
                <p className="viewport_animation" data-vp-add-class="animated fadeInUp">Крупные порталы с постоянной
                  текучкой финансов требуют пристального внимания со стороны не только бухгалтерии, но и отдела
                  маркетинга, а также руководящего сектора. Именно для удобства работы вышеуказанных специалистов
                  нами была разработана многофункциональная панель управнелия.

                  Все метрики и графики отчётностей построены и расположены таким образом, чтобы у контролирующих
                  финансовую успешность проекта специалистов был доступен максимум возможностей для анализа и
                  проверки текущей информации, формирующейся порталом. Всего за несколько кликов менеджеры могут
                  собрать отчётность, необходимую для планёрки или построения бюджета при долгосрочном
                  планировании.</p>
              </div>
              <div className="column">
                <img src="/img/bison/task.png" alt="" className="bison__image" />
              </div>
            </div>
          </div>
          <div className="total">
            <div className="container inf-cnt">
              <span className="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Итого</span>
              <h2 className="viewport_animation" data-vp-add-class="animated fadeInLeft">Затраты в цифрах</h2>
              <ul>
                {/*<li className="viewport_animation" data-vp-add-class="animated fadeInLeft">
              <h3>Над проектом работали:</h3>
              <span>18 Человек</span>
              <p>2 дизайнера<br />
                1 менеджер проектов<br />
                6 Backend-разработчиков<br />
                5 Тестировщиков<br />
                3 Маркетолога<br />
                1 Frontend-разработчик</p>
            </li> */}
                <li className="viewport_animation" data-vp-add-class="animated fadeInUp">
                  <h3>Всего затрачено</h3>
                  <span>~ 20 000 часов</span>
                  <p>8 месяцев разработки</p>
                </li>
                <li className="viewport_animation" data-vp-add-class="animated fadeInRight">
                  <h3>Стоимость</h3>
                  <span>129 800 €</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ProjectButtons />
    </div >

  );
};
