export const VACANCY_LIST = [
  {
    name: 'DevOps Engineer',
    description:
      'Ищем специалиста, который будет отвечать за автоматизацию и оптимизацию процессов разработки и внедрение программного обеспечения. Который будет работать в тесном сотрудничестве с разработчиками, который сможет быстро и эффективно развертывать и поддерживать программное обеспечение. Который также будет заниматься мониторингом и управлением производительностью приложений, обеспечением безопасности и совместимости с другими системами. Если вы умеете работать в команде, готовы к постоянному развитию своих навыков и стремитесь к созданию высокоэффективных инфраструктур, то мы будем рады видеть вас в нашей команде.',
    duties: [
      'Поддержка существующих проектов;',
      'Взаимодействие с командами разработки для организации сквозных процессов DevOps;',
      'Заниматься мониторингом и управлением производительностью приложений;',
    ],
    requirements: [
      'Опыт коммерческой работы на позиции DevOps 3-х лет;',
      'Опыт работы с решениями контейнерной виртуализации Docker;',
      'Понимание базовых принципов подходов DevOps (CI\\CD);',
      'Опыт участия в автоматизации доставки приложений;',
      'Опыт работы с микросервисными архитектурами;',
      'Опыт работы с системой хранения исходных кодов GIT;',
      'Опыт ведения проектов в Jira,Confluence.',
      'Умение оценивать объем и сроки работ;',
      'Профильное высшее образование будет Вашим плюсом.',

    ],
    conditions: [
      'Удаленная работа из любой точки мира;',
      'Официальное трудоустройство и полностью белая ЗП;',
      'Достойная заработная оплата труда пропорциональная вашему уровню знаний;',
      'Оплачиваемый отпуск/больничный;',
      'Возможность наблюдать результат своей работы и влиять на развитие проектов;'
    ],
  },
  {
    name: 'PHP Developer',
    description:
      'Ищем PHP Developer, который будет заниматься разработкой и поддержкой наших веб-приложений на PHP. Мы ищем опытного специалиста, который имеет глубокие знания PHP, MySQL и опыт работы с фреймворками, такими как Laravel, Phalcon или Symfony. Если вы умеете работать в команде, готовы к постоянному развитию своих навыков и стремитесь к созданию качественного кода, то мы будем рады видеть вас в нашей команде.',
    duties: [
      'Проектирование, разработка и поддержка кода на PHP;',
      'Разработка модулей к существующим проектам;',
      'Проектирование и разработка REST API;',
      'Реализация интеграций с внешними сервисами;',
      'Улучшение существующего кода и решений;',
    ],
    requirements: [
      'Опыт PHP-разработки от 3 лет;',
      'Знание принципов ООП, REST API, MVC, SOLID, DRY, KISS, HTTP;',
      'Знание шаблонов проектирования;',
      'Опыт работы с фреймворками (предпочтительно от 3-ух лет с Laravel, Phalcon или Symfony);',
      'Знание MySQL, Docker, Redis, Git;',
      'Умение оценивать объем и сроки работ;',
      'Профильное высшее образование будет Вашим плюсом.',
    ],
    conditions: [
      'Удаленная работа из любой точки мира;',
      'Официальное трудоустройство и полностью белая ЗП;',
      'Достойная заработная оплата труда пропорциональная вашему уровню знаний;',
      'Оплачиваемый отпуск/больничный;',
      'Возможность наблюдать результат своей работы и влиять на развитие проектов;'
    ],
  },
  {
    name: 'Front-end developer',
    description:
      'Ищем Front-end developer, который будет заниматься разработкой пользовательского интерфейса для наших веб-сайтов и приложений. Мы ищем опытного специалиста, который сможет создавать красивые и удобные интерфейсы, а также обеспечивать высокую производительность и оптимизацию сайта. Если вы имеете опыт работы с React, Docker, Git а также умеете работать в команде и готовы к постоянному развитию своих навыков, то мы будем рады видеть вас в нашей команде.',
    duties: [
      'Поддержка и обновление существующих приложений;',
      'Разработка и поддержка компонентов React;',
      'Работа с Redux или другими библиотеками управления состоянием;',
      'Интеграция API;',
      'Оптимизация производительности и улучшение пользовательского интерфейса;',
      'Участие в код-ревью и коллективная разработка;',
      'Взаимодействие с дизайнерами и другими участниками команды для достижения целей проекта;',
      'Исследование и применение новых технологий и инструментов для улучшения разработки;',
    ],
    requirements: [
      'Коммерческий опыт работы в позиции Frontend developer (React) от 2-х лет;',
      'Отличное знание языков JavaScript,Typescript;',
      'Знания React, Redux, React-router;',
      'Опыт работы с Docker, Git;',
      'Понимание основных принципов построения приложений и архитектурных решений;',
      'Умение оценивать объем и сроки работ;',
      'Профильное высшее образование будет Вашим плюсом.',
    ],
    conditions: [
      'Удаленная работа из любой точки мира;',
      'Официальное трудоустройство и полностью белая ЗП;',
      'Достойная заработная оплата труда пропорциональная вашему уровню знаний;',
      'Оплачиваемый отпуск/больничный;',
      'Возможность наблюдать результат своей работы и влиять на развитие проектов;'
    ],
  },
];
