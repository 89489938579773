import React, { useEffect } from 'react';
import { ProjectButtons } from '../../ProjectButtons/index.js';

export const Dota = () => {

  useEffect(() => {
    import('../assets/js/additional.min.js')
      .then(() => import('../assets/js/main.min.js')
        .then(() => import('../assets/js/dota.min.js'))
      )
  }, [])

  return (
    <div>
      <canvas id="maxline-chart" height="400" style={{ display: 'none' }}></canvas>
      <canvas id="maxline-chart" height="400" style={{ display: 'none' }}></canvas>
      <div class="content">
        <div class="portfolio-slider">
          <div class="item">
            <img src="/img/background/dota.png" alt="" />
            <div class="inner-text">
              <div class="border">
                <img class=" viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/min-dota.png" alt="Конфигуратор" />
                <h1 class=" viewport_animation" data-vp-add-class="animated fadeInRight">Академия Киберспорта
                </h1>
                <p class=" viewport_animation" data-vp-add-class="animated fadeInRight">Сайт для проведения онлайн
                  турниров по Dota2</p>
              </div>
            </div>
          </div>
        </div>
        <div class="portfolio" id="portfolio">
          <div class="task -gray">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Концепция
                проекта</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Киберспорт уже давно захватил
                современный рынок, с каждым днём увеличивая количество геймеров по всему миру.</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Dota 2 - легендарная игра со
                всемирно известным турниром The International, которая ежегодно открывает новые таланты, привлекая
                игроков невероятными суммами призовых денег.

                Целью заказчика было создание нового портала для проведения онлайн-турниров среди киберспортсменов
                Dota 2. Киберарена BFC, разработанная нашей командой - идеальная платформа, где лучшие игроки Dota 2
                могут проверить уровень своих навыков и заработать на победах.
              </p>
            </div>
          </div>
          <div class="steps -dota" id="fire-bg-1">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Этапы работы над
                проектом</span>
              <ul class="step_list viewport_animation dotaList" data-vp-add-class="animated fadeInUp">
                <li>
                  <span class="p-1">1</span>
                  <p>Обсуждение проекта
                    Заполнение брифа</p>
                </li>
                <li>
                  <span class="p-2">2</span>
                  <p>Составление ТЗ
                    на разработку сайта</p>
                </li>
                <li>
                  <span class="p-3">3</span>
                  <p>Оценка стоимости
                    и сроков разработки</p>
                </li>
                <li>
                  <span class="p-4">4</span>
                  <p>Фронтенд
                    (верстка сайта)</p>
                </li>
                <li>
                  <span class="p-5">5</span>
                  <p>Интеграция
                    Steam API</p>
                </li>
                <li>
                  <span class="p-6">6</span>
                  <p>Бэкенд
                    (программирование)</p>
                </li>
                <li>
                  <span class="p-7">7</span>
                  <p>Тестирование</p>
                </li>
                <li>
                  <span class="p-8">8</span>
                  <p>Запуск проекта</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="-gray">
            <div class="container inf-cnt">
              <div class="text">
                <span class="step-name ">
                  — Задачи проекта</span>
                <div class="elem">
                  <div class="textDota">
                    <h2>Создание крупнейшей площадки для проведения онлайн турниров по Dota 2</h2>
                    <p>Главной задачей проекта было не только запустить платформу по мировым турнирам, но и
                      сделать её такой, чтобы она привлекала и удерживала целевую аудиторию.

                      Для этого при продумывании архитектуры портала, наша команда проанализировала схожие
                      проекты европейского рынка и учла всех их плюсы в своей разработке.

                      Дизайн киберарены BFC представляет портал современным, запоминающимся и максимально
                      удобным в использовании, что позволяет заказчику удерживать и наращивать
                      пользовательскую базу.
                    </p>
                  </div>
                  <br />
                  <div>
                    <div>
                      <img src="/img/dota/slide_1.png" alt="Без контейнера" data-container="without" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dota-team">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Создание
                команды</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Приглашение участников команды в
                турнир</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Функционал создания команды
                реализован с учётом дальнейшего приглашения тиммейтов в турниры. Любой из участников может вступить
                в турнир и пригласить четырёх игроков из своей команды. Вся информация о турнире (название, дата,
                награда и свободные места) доступна игрокам к просмотру.</p>
              <div class="table">
                <div class="players viewport_animation" data-vp-add-class="animated fadeInLeft">
                  <div class="player captain">
                    <div class="text">
                      <span>Игрок 1</span>
                      <p>Капитан</p>
                    </div>
                    <img src="/img/dota/captain_logo.png" alt="" />
                  </div>
                  <div class="player">
                    <div class="text">
                      <span>Игрок 2</span>
                    </div>
                    <img src="" alt="" class="new_player" />
                  </div>
                  <div class="player">
                    <div class="text">
                      <span>Игрок 3</span>
                    </div>
                    <img src="" alt="" />
                  </div>
                  <div class="player">
                    <div class="text">
                      <span>Игрок 4</span>
                    </div>
                    <img src="" alt="" />
                  </div>
                  <div class="player">
                    <div class="text">
                      <span>Игрок 5</span>
                    </div>
                    <img src="" alt="" />
                  </div>
                </div>
                <div class="tournaments viewport_animation" data-vp-add-class="animated fadeInRight">
                  <table>
                    <thead>
                      <tr>
                        <th>Турнир</th>
                        <th>Начало</th>
                        <th>Места</th>
                        <th>Награда</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="tournament-table">
                      <tr>
                        <td>Турнир #0121
                          <b>Daily champ</b>
                        </td>
                        <td>
                          <b>27.09.17</b>
                          <span class="timeFrom"></span>
                        </td>
                        <td>
                          <b>10 / 16</b>
                        </td>
                        <td>
                          <b>3200</b>
                        </td>
                        <td>
                          <a href="#fire-bg-2" class="anchor">Участвовать</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Турнир #0122
                          <b>Daily champ 2</b>
                        </td>
                        <td>
                          <b>27.09.17</b>
                          <span class="timeFrom"></span>
                        </td>
                        <td>
                          <b>2 / 16</b>
                        </td>
                        <td>
                          <b>1100</b>
                        </td>
                        <td>
                          <a href="#fire-bg-2" class="anchor">Участвовать</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Турнир #0123
                          <b>Daily champ 3</b>
                        </td>
                        <td>
                          <b>27.09.17</b>
                          <span class="timeFrom"></span>
                        </td>
                        <td>
                          <b>10 / 16</b>
                        </td>
                        <td>
                          <b>700</b>
                        </td>
                        <td>
                          <a href="#fire-bg-2" class="anchor">Участвовать</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <a href="#" id="add_tournament">Ещё турниры</a>
                </div>
                <img src="/img/dota/antimag.gif" class="dota_animation viewport_animation" data-vp-add-class="animated zoomIn" alt="" />
              </div>
            </div>
          </div>
          <div class="dota-tournament" id="fire-bg-2">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Турнирная
                сетка</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Автоматическая генерация по итогам
                игр между командами</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Никакого дополнительного
                вмешательства - платформа автоматически создаёт и настраивает турнирную сетку в зависимости от
                количества команд. Для каждой из команд определяется соперник. В том случае, когда количество команд
                нечётное, команда без соперника автоматически попадает в следующий этап турнира.</p>
              <div class="grid">
                <div class="step-1 viewport_animation" data-vp-add-class="animated fadeInLeft">
                  <div class="pvp" id="first-cart">
                    <div class="card">
                      <p>Команда №1</p>
                      <span>0</span>
                    </div>
                    <div class="card">
                      <p>Команда №2</p>
                      <span>0</span>
                    </div>
                  </div>
                  <div class="pvp" id="second-cart">
                    <div class="card">
                      <p>Команда №3</p>
                      <span>0</span>
                    </div>
                    <div class="card">
                      <p>Команда №4</p>
                      <span>0</span>
                    </div>
                  </div>
                </div>
                <div class="step-2 viewport_animation" data-vp-add-class="animated fadeInUp">
                  <div class="pvp" id="third-cart">
                    <div class="card">
                      <p>К1 vs К2</p>
                      <span>0</span>
                    </div>
                    <div class="card">
                      <p>К3 или К4</p>
                      <span>0</span>
                    </div>
                  </div>
                </div>
                <div class="step-3 viewport_animation" data-vp-add-class="animated fadeInRight" id="four-cart">
                  <div class="card">
                    <p>Неизвестно</p>
                    <span style={{ color: 'transparent', userSelect: 'none' }}></span>
                  </div>
                </div>
                <div class="begin viewport_animation" data-vp-add-class="animated fadeInUp">
                  <a href="#" id="tournament_begin">Начать турнир</a>
                </div>
              </div>
            </div>
          </div>
          <div class="dota-api -gray">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — SteamAPI</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Портал плотно взаимодействует
                с<br />
                крупнейшей игровой площадкой Steam</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Киберарена BFC напрямую
                взаимодействует с крупнейшей игровой площадкой Steam. Через платформу Steam происходит отправка
                запроса в игровой клиент Dota 2, откуда портал получает информацию о результатах игры после
                завершения матча. Все результаты своевременно попадают на сайт киберарены. На основании полученных
                данных, на портале формируется статистика игроков.</p>
              <div class="browser-api">
                <div class="browser viewport_animation" data-vp-add-class="animated fadeInLeft">
                  <img src="/img/dota/browser.png" alt="" />
                </div>
                <div class="api viewport_animation" data-vp-add-class="animated fadeInUp">
                  <img src="/img/dota/steam_api.png" style={{ width: '80%' }} alt="" />
                </div>
                <div class="api viewport_animation" data-vp-add-class="animated fadeInRight">
                  <img src="/img/dota/api.png" alt="" />
                </div>

              </div>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Благодаря такой плотной
                синхронизации трёх ключевых модулей, мы получили возможность не только создавать лобби внутри
                клиента игры, а ещё и отправлять приглашения игрокам в автоматически создаваемые матчи. Кроме этого
                все результаты игр выводятся на сайте в профиле пользователя, что позволяет сторонним наблюдателям
                выделять наиболее успешных игроков среди общей массы участников портала. Благодаря этим функциям,
                капитаны команд могут производить набор в свои группы и расширять состав участников. Таким образом
                мы полчили растущую площадку для проведения турниров.<br />Работая над этим проектом, мы столкнулись главным образом с задачами по проэктированию и созданию
                моделей поведения пользователей. Было проработано множество схем взаимодействия площадки и
                посетителей с целью подбора и оптимизации наиболее удобного варианта. Слаженная работа по
                тестированию проекта, регулярный фидбек от тестировщиков и быстрые правки программистов позволили
                вывести портал на достойный уровень к дате релиза. Благодаря этой разработке мы внесли свой вклад в
                дружественную атмосферу азарта и конкуренции, позволяющую растить профессиональных игроков в
                индустрии киберспорта.
              </p>
            </div>
          </div>
          <div class="total">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Итого</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Затраты в цифрах</h2>
              <ul>
                <li class="viewport_animation" data-vp-add-class="animated fadeInUp">
                  <h3>Всего затрачено</h3>
                  <span>3 600 часов</span>
                  <p>тех. задание 80 часов<br />
                    фронтенд 950 часов<br />
                    бэкенд 2330 часов<br />
                    тестирование 240 часов</p>
                </li>
                <li class="viewport_animation" data-vp-add-class="animated fadeInRight">
                  <h3>Стоимость</h3>
                  <span>90 000 €</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ProjectButtons />
    </div >

  );
};
