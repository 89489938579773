import React, { useEffect } from 'react';
import { ProjectButtons } from '../../ProjectButtons';

export const Alfa = () => {
  useEffect(() => {
    import('../assets/js/additional.min.js')
      .then(() => import('../assets/js/main.min.js')
        .then(() => import('../assets/js/configurator.min.js')))
  }, [])

  return (
    <div>
      <div class="content">
        <div class="portfolio-slider">
          <div class="item">
            <img src="/img/background/configurate.png" alt="" />
            <div class="inner-text">
              <div class="border">
                <img class=" viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/alfa_configurator.png" alt="Конфигуратор" />
                <h1 class=" viewport_animation" data-vp-add-class="animated fadeInRight">Конфигуратор ALFA</h1>
                <p class=" viewport_animation" data-vp-add-class="animated fadeInRight">Разработка сайта по продаже
                  котельного оборудования<br />
                  Дизайн, Вёрстка, Программирование.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="portfolio" id="portfolio">
          <div class="conf-screen -gray">
            <img class="left viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/configurator/screen2.png" alt="Левый" />
            <img class="center viewport_animation" data-vp-add-class="animated fadeInUp" src="/img/configurator/screen1.png" alt="Центральный" />
            <img class="right viewport_animation" data-vp-add-class="animated fadeInRight" src="/img/configurator/screen3.png" alt="Правый" />
          </div>
          <div class="task -gray">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Концепция
                проекта</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">ALFA Configurator - уникальное
                решение для продажи котельного оборудования.</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">ALFA помогает поэтапно подобрать под
                запрос клиента идеальный вариант котельной системы, составляет коммерческое предложение и оставляет
                приятные впечатления своей наглядностью и удобством использования для потенциальных покупателей. При
                разработке были изучены и добавлены в проект все необходимые формулы расчёта для подбора подходящих
                моделей.</p>
            </div>
          </div>
          <div class="conf-prototype">
            <div class="container">
              <div class="text">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInRight">
                  — Ведущая
                  идея</span>
                <h2 class="viewport_animation" data-vp-add-class="animated fadeInRight">Визуализация</h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInRight">Всё оборудование на сайте
                  представлено в двух вариантах - наружный и внутренний. Наружный вариант позволяет оценить
                  масштабность и внешний вид, в то время как внутренний представляет к детальному обзору все
                  элементы оборудования.

                  В процессе дизайна было создано и внедрено в конфигуратор более сотни разнообразных графических
                  элементов. Для этого наша команда изучила достаточно большое количество отдельных частей
                  котельного оборудования. Каждое изображение согласовывалось с заказчиком и лишь после этого
                  внедрялось в проект.

                  Несмотря на большой объём работы, этап дизайна и тестирования прошёл в максимально короткие
                  сроки.
                </p>
              </div>
              <div class="action">
                <div class="image imgCont">
                  <img src="/img/configurator/background_without.png" alt="Без контейнера" data-container="without" class="animated fadeOut" />
                  <img src="/img/configurator/background_with.png" alt="С контейнером" data-container="with" />
                </div>
                <div class="button">
                  <input type="radio" name="container_see" value="with" checked title="Посмотрите с контейнером" />
                  <input type="radio" name="container_see" value="without" title="Посмотрите без контейнера" />
                </div>
              </div>
            </div>
          </div>
          <div class="conf-prototype">
            <div class="container -right">
              <div class="text">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  — Логика</span>
                <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Составление торгового
                  предложения</h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInLeft">Одним из главных достоинств
                  проекта является пошаговое формирование торгового предложения. Это позволяет достаточно чётко
                  определить потребности покупателя, что в свою очередь делает торговое предложение максимально
                  удовлетворяющим клиентский запрос. Добавление каждого нового элемента котельного оборудования
                  происходит поэтапно. На первом этапе формируется основа, после чего к ней добавляются
                  дополнительные составляющие.

                  В каждом из возможных шагов предусмотрено несколько вариаций для выбора. Например, при
                  добавлении топливного бака можно выбрать его объём и сразу же оценить планируемый бюджет под
                  свой запрос.

                  Кроме финансового модуля, который рассчитывает и отображает стоимость, реализована визуализация
                  каждого шага построения системы. Схематичные изображения оборудования на всех этапах позволяют
                  покупателю оценить потенциальные изменения в размерах выбранного им модуля.
                </p>
              </div>
              <div class="action">
                <div class="image">
                  <img src="/img/configurator/module_1.png" alt="" data-module="1" />
                  <img src="/img/configurator/module_2.png" alt="" data-module="2" style={{ display: "none" }} />
                  <img src="/img/configurator/module_3.png" alt="" data-module="3" style={{ display: "none" }} />
                  <img src="/img/configurator/module_4.png" alt="" data-module="4" style={{ display: "none" }} />
                </div>
                <div class="button">
                  <div class="checkbox">
                    <input type="checkbox" name="construct_1" id="construct_1" value="1" checked disabled title="" />
                    <label for="construct_2"></label>
                    <div class="hint">
                      <img src="/img/configurator/truba.png" alt="" />
                      <div class="text">
                        <h4>Шаг 1</h4>
                        <p>Добавьте
                          <br />
                          дымовую трубу...</p>
                      </div>
                    </div>
                  </div>
                  <div class="checkbox">
                    <input type="checkbox" name="construct_2" id="construct_2" value="2" />
                    <label for="construct_2"></label>
                    <div class="hint">
                      <img src="/img/configurator/truba.png" alt="" />
                      <div class="text">
                        <h4>Шаг 2</h4>
                        <p>Добавьте
                          <br />
                          дымовую трубу...</p>
                      </div>
                    </div>
                  </div>
                  <div class="checkbox posHint3">
                    <input type="checkbox" name="construct_3" id="construct_3" value="3" />
                    <label for="construct_3"></label>
                    <div class="hint">
                      <img src="/img/configurator/truba.png" alt="" />
                      <div class="text">
                        <h4>Шаг 3</h4>
                        <p>Добавьте
                          <br />
                          дымовую трубу...</p>
                      </div>
                    </div>
                  </div>
                  <div class="checkbox posHint4">
                    <input type="checkbox" name="construct_4" id="construct_4" value="4" />
                    <label for="construct_4"></label>
                    <div class="hint">
                      <img src="/img/configurator/truba.png" alt="" />
                      <div class="text">
                        <h4>Шаг 4</h4>
                        <p>Добавьте
                          <br />
                          дымовую трубу...</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="conf-prototype">
            <div class="container">
              <div class="text">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInRight">
                  —
                  Интуитивность</span>
                <h2 class="viewport_animation" data-vp-add-class="animated fadeInRight">Выбор модели</h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInRight">Значимое преимущество ALFA -
                  простой и понятный интерфейс. На каждом шаге построения предложения пользователю достаточно
                  задать необходимые параметры или выбрать доступные из списков варианты.

                  На примере выбора модели можно увидеть, как при указании количества газоотводов и высоты дымовой
                  трубы, конфигуратор самостоятельно вычисляет и предлагает модель под запрос.

                  В результате всех действий, клиент получит коммерческое предложение на котельное оборудование по
                  своим потребностям в формате pdf.
                </p>
                <form action="">
                  <h3>Выберите модель:</h3>
                  <div class="row">
                    <div class="form-group">
                      <label for="gas">Количество газоотводов:</label>
                      <div class="select">
                        <select name="gas" id="gas">
                          <option value="0" selected disabled>Выбрать</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="height_gas">Высота дымовой трубы:</label>
                      <div class="select">
                        <select name="height-gas" id="height_gas">
                          <option value="0" selected disabled>Выбрать</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="action">
                <div class="row">
                  <img src="/img/configurator/truba_1.png" alt="K0" data-pipe="1" />
                  <img src="/img/configurator/truba_2.png" alt="K2" data-pipe="2" />
                  <img src="/img/configurator/truba_3.png" alt="K3" data-pipe="3" />
                  <img src="/img/configurator/truba_4.png" alt="K3-U" data-pipe="4" />
                  <img src="/img/configurator/truba_5.png" alt="K4" data-pipe="5" />
                  <div class="img" id="secret_image">
                    <canvas class="smoke" id="sgduman"></canvas>
                    <img src="/img/configurator/truba_6.png" alt="K6" data-pipe="6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="conf-pdf -gray">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInRight">
                —
                Документация</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInRight">Онлайн генерация PDF документа
                со статичной ссылкой</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInRight">Кроме онлайн расчёта стоимости
                котельного оборудования сайт предполагает возможность сохранить получившийся расчёт в виде
                pdf-документа, хранящегося на постоянной ссылке. Для посетителя будет удобно скопировать ссылку на
                своё коммерческое предложение и при необходимости переходить прямо к нему.<br />
                <br />
                Доступ к сформированным коммерческим предложениям на сайте имеют также и менеджеры со стороны
                владельцев сайта, которые при необходимости используют данные расчёты в процессе общения с
                заказчиками. Функционал сайта предусматривает удобную распечатку сгенерированного документа, а также
                возможность сохранить файл к себе на компьютер для дальнейшего его использования.<br />
                <br />
                В результате всех выполненных работ, заказчик получил удобный и эффективный инструмент для
                привлечения целевой аудитории, а также формирования и генерации коммерческих предложений в режиме
                онлайн.
              </p>
              <div class="image">
                <img src="/img/configurator/pdf_screen.png" class="browser viewport_animation" data-vp-add-class="animated fadeInLeft" alt="browser" />
                <div class="pdf viewport_animation" data-vp-add-class="animated fadeInLeft">
                  <img src="/img/configurator/pdf.png" alt="pdf" />
                </div>
              </div>
            </div>
          </div>
          <div class="total">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Итого</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Ресурсы разработки в цифрах</h2>
              <ul>
                <li class="viewport_animation" data-vp-add-class="animated fadeInUp">
                  <h3>Всего затрачено</h3>
                  <span>1 700 часов</span>
                  <p>Тех. задание 40 часов<br />
                    Прототипирование 60 часов<br />
                    Дизайн страниц 160 часов<br />
                    Фронтенд 360 часов<br />
                    Бэкенд 1010 часов<br />
                    Тестирование 70 часов</p>
                </li>
                <li class="viewport_animation" data-vp-add-class="animated fadeInRight">
                  <h3>Примерная стоимость проекта на сегодняшний день</h3>
                  <span>43 000 €</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ProjectButtons />
    </div>
  );
};
