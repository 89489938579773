import React, { useEffect } from 'react';
import { ProjectButtons } from '../../ProjectButtons';
import styles from './styles.module.scss';

export const Ostapilet = () => {
  useEffect(() => {
    import('../assets/js/additional.min.js')
      .then(() => import('../assets/js/main.min.js')
        .then(() => import('../assets/js/ost-steps.js')))
  }, [])

  return (
    <div className={styles.container}>
      <div class="content">
        <div class="portfolio-slider">
          <div class="item">
            <img src="/img/background/cashier.png" alt="" />

            <div class="inner-text">
              <div class="border">
                <img class=" viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/min-cashier.png" alt="Билетчики" />
                <h1 class=" viewport_animation" data-vp-add-class="animated fadeInRight">Кейс Кассир</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="portfolio" id="portfolio">

          <div class="task">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Введение</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Ostapilet - современная система
                продажи билетов.</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Давно осталось в прошлом то, что за
                покупкой билетов на концерт любимой музыкальной группы или киносеанс нового блокбастера приходилось
                заранее обращаться в кассу для покупки билетов, подолгу стоять в очередях, надеясь на наличие
                свободных мест. Помимо всего прочего, раньше зачастую возникала путаница с продажей по рассадке в
                зале, бумажный билет легко терялся, а при отмене мероприятия организаторы не имели возможности
                своевременно оповестить каждого покупателя билета. Современный зритель привык к комфорту и не готов
                впустую растрачивать своё время.

                Портал ostapilet.ee, разработанный нашей компанией для стран Евросоюза, позволил поднять массовую
                реализацию билетов на новый уровень. В одной системе собраны решения для зрителя, контроллера и
                организатора.
              </p>
            </div>
          </div>
          <div class="steps">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Этапы работы над
                проектом</span>
              <ul class="step_list viewport_animation cashList" data-vp-add-class="animated fadeInUp">
                <li>
                  <span class="p-1">1</span>
                  <p>Обсуждение проекта
                    Заполнение брифа</p>
                </li>
                <li>
                  <span class="p-2">2</span>
                  <p>Составление ТЗ
                    на разработку сайта</p>
                </li>
                <li>
                  <span class="p-3">3</span>
                  <p>Прототипирование
                    страниц</p>
                </li>
                <li>
                  <span class="p-4">4</span>
                  <p>Дизайн главной
                    страницы</p>
                </li>
                <li>
                  <span class="p-5">5</span>
                  <p>Дизайн внутрениих
                    страниц</p>
                </li>
                <li>
                  <span class="p-6">6</span>
                  <p>Фронтенд
                    (верстка сайта)</p>
                </li>
                <li>
                  <span class="p-7">7</span>
                  <p>Бэкенд
                    (программирование)</p>
                </li>
                <li>
                  <span class="p-8">8</span>
                  <p>Разработка мобильного
                    приложения</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="prototype">
            <div class="container">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                —
                Прототипирование</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Особенности решения</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInLeft">
                Перед нашей компанией стояла задача разработать удобный портал с запоминающимся дизайном, в котором
                реализована продажа электронных билетов на мероприятия. На этапе прототипирования был детально
                продуман внешний вид портала в соответствии с поставленным к реализации функционалом.

                Главные функции заключались в добавлении мероприятий, продаже билетов, разграничении работы в
                системе по ролям “Пользователь”, “Организатор” и “Контролёр”. Мы расширили возможности функционала
                портала, добавив в него языковой модуль и конструктор залов.
              </p>
              <img src="/img/mockup/2.png" alt="" class="small viewport_animation" data-vp-add-class="animated fadeInUp" />
              <img src="/img/mockup/1.png" alt="" class="big viewport_animation" data-vp-add-class="animated fadeInUp" />
            </div>
          </div>
          <div class="design">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Разработка</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Технологии</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInLeft">При разработке портала команда
                использовала в работе следующие современные технологии: PHP7, FPM, Phalcon framework, Composer,
                jQuery, Bootstrap 3, Fabricjs, MySQL 5.7, Redis, Git, Nginx.
              </p>
              <div class="image viewport_animation" data-vp-add-class="animated fadeInUp">
                <figure class="cd-image-container">
                  <img src="/img/img1.png" alt="Original Image" />
                  <span class="cd-image-label" data-type="original"></span>

                  <div class="cd-resize-img">
                    <img src="/img/img2.png" alt="Modified Image" />
                    <span class="cd-image-label" data-type="modified"></span>
                  </div>

                  <span class="cd-handle"></span>
                </figure>
              </div>
            </div>
          </div>
          <div class="detail">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Основной
                функционал</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Удобство использования сайта
                превыше всего</h2>
              <div class="tabs viewport_animation" data-vp-add-class="animated fadeInUp">
                <input type="radio" name="detail" defaultChecked id="adaptive" />
                <label for="adaptive">Для зрителя</label>
                <div class="inner-text">
                  <p>Пользователю сайта доступна информация и афиша по предстоящим мероприятиям. Покупая билет на
                    мероприятие, пользователь видит рассадку в зале и может выбрать места. Оплата билетов сейчас
                    доступна с банковских карт Swedbank, SEB, LHV, Visa, Mastercard. Купить билеты можно как
                    зарегистрированным пользователям, так и без регистрации на сайте (с указанием e-mail для
                    отправки электронного билета).
                  </p>
                  <div class="devices">
                    <div class="tablet">
                      <img src="/img/mockup/ipad.gif" alt="" />
                    </div>
                    <div class="laptop">
                      <img src="/img/mockup/mackbook.gif" alt="" />
                    </div>
                    <div class="pc">
                      <img src="/img/mockup/pc.gif" alt="" />
                      <img src="/img/background/pc.png" class="back" alt="" />
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
                <input type="radio" name="detail" id="udobsptvo" />
                <label for="udobsptvo">Для контролёра</label>
                <div class="inner-text">
                  <p>Контролёр считывает сканером штрих-код на электронном билете, после чего происходит
                    автоматическая проверка билета и создание записи о том, что контроль пройден.
                  </p>
                  <div class="devices">
                    <div class="tablet">
                      <img src="/img/mockup/ipad.gif" alt="" />
                    </div>
                    <div class="laptop">
                      <img src="/img/mockup/mackbook.gif" alt="" />
                    </div>
                    <div class="pc">
                      <img src="/img/mockup/pc.gif" alt="" />
                      <img src="/img/background/pc.png" class="back" alt="" />
                    </div>
                  </div>
                </div>
                <input type="radio" name="detail" id="mobile" />
                <label for="mobile">Для организатора</label>
                <div class="inner-text">
                  <p>Любой организатор мероприятий может заполнить форму на сайте, которую рассматривает и
                    одобряет администратор. После одобрения, организатор загружает событие на сайт для
                    реализации билетов.
                  </p>
                  <div class="devices">
                    <div class="tablet">
                      <img src="/img/mockup/ipad.gif" alt="" />
                    </div>
                    <div class="laptop">
                      <img src="/img/mockup/mackbook.gif" alt="" />
                    </div>
                    <div class="pc">
                      <img src="/img/mockup/pc.gif" alt="" />
                      <img src="/img/background/pc.png" class="back" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="personal">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Резюме</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Преимущества решения
              </h2>
              <div></div>
              <div class="left viewport_animation" data-vp-add-class="animated fadeInLeft" id="place">
                <p class="decision">Уникальный конструктор залов, который включает выделение секторов на схеме зала,
                  полное редактирование как посадочных, так и входных мест;

                  Адаптивная верстка для пользователей смартфонов;

                  Локализация сайта (на латышском, эстонском, русском и английском языках);

                  Мобильное приложение для контролера для получения информации о статусе сканируемого билета;

                  Яркие, притягивающие цвета и простота восприятия дизайна.
                </p>
                <br />
                <div class="ticket -success" id="ticket">
                  <div class="top">
                    <img src="/img/icon/tickets.png" alt="" />
                    <span>Билет на концерт</span>
                    <p>Ряд 4 место 11</p>
                  </div>
                  <p>Билет одобрен</p>
                </div>
              </div>
              <div class="right">
                <div class="action viewport_animation" data-vp-add-class="animated fadeInRight">
                  <div class="scanner">
                    <img src="/img/ticket_scanner.png" alt="" />
                  </div>
                  <img src="/img/tickets_two.png" alt="" class="tickets" />
                </div>
                <div class="button viewport_animation" data-vp-add-class="animated fadeInUp">
                  <input type="radio" name="ticket_radio" id="ticket_check" defaultChecked />
                  <label htmlFor="ticket_check" data-for="ticket_check">Проверить билет</label>
                </div>
                <div class="button viewport_animation" data-vp-add-class="animated fadeInUp">
                  <input type="radio" name="ticket_radio" id="ticket_change" />
                  <label htmlFor="ticket_change" data-for="ticket_change" style={{ marginLeft: "0", marginRight: "30px" }}>Поменять билет</label>
                </div>
              </div>
            </div>
          </div>
          <div class="total">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Итого</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Ресурсы разработки в цифрах
              </h2>
              <ul>
                <li class="viewport_animation" data-vp-add-class="animated fadeInUp">
                  <h3>Всего затрачено</h3>
                  <span>5 000 часов</span>
                  <p>тех. задание 88 часов<br />
                    прототипирование 96 часов<br />
                    дизайн страниц 328 часов<br />
                    фронтенд 1480 часов<br />
                    бэкенд 2748 часов<br />
                    тестирование 260 часов</p>
                </li>
                <li class="viewport_animation" data-vp-add-class="animated fadeInRight">
                  <h3>Примерная стоимость проекта на сегодняшний день</h3>
                  <span>120 000 €</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ProjectButtons />
    </div>
  );
};
