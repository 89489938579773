export const LOCALE_LIST = [
  {
    id: 0,
    value: 'ru',
  },
  {
    id: 1,
    value: 'en',
  },
];
