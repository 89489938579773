import React from "react";
import '../assets/css/steps.css'
const steps = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 662.47" class=""
            >
                <defs>
                    <style>

                    </style>
                    <linearGradient id="f029bd49-42a5-451a-8180-ed5960183037" x1="458.95" y1="479.07" x2="401.46" y2="421.58"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#3faae0"></stop>
                        <stop offset="1" stop-color="#46bbc3"></stop>
                    </linearGradient>
                    <linearGradient id="51ded12e-3260-4581-a6af-823139546b22" x1="640.45" y1="358.02" x2="582.96" y2="300.53"
                        xlink="#f029bd49-42a5-451a-8180-ed5960183037"></linearGradient>
                    <linearGradient id="9472fac0-3f21-4ee9-8fb9-1efe24976e69" x1="821.05" y1="534.42" x2="763.56" y2="476.93"
                        xlink="#f029bd49-42a5-451a-8180-ed5960183037"></linearGradient>
                    <linearGradient id="9d9d7c27-cc7f-47f7-817d-2ce3c2ec2c6f" x1="1004.05" y1="290.82" x2="946.56" y2="233.33"
                        xlink="#f029bd49-42a5-451a-8180-ed5960183037"></linearGradient>
                    <linearGradient id="9f18339e-c8a4-479f-8fef-a4c2b37591b5" x1="1186.45" y1="177.42" x2="1128.96" y2="119.93"
                        xlink="#f029bd49-42a5-451a-8180-ed5960183037"></linearGradient>
                    <linearGradient id="e2f432ba-53fa-48b7-b914-b2eb305a11a3" x1="1367.05" y1="492.42" x2="1309.56" y2="434.93"
                        xlink="#f029bd49-42a5-451a-8180-ed5960183037"></linearGradient>
                    <linearGradient id="e86aa751-7db4-46fa-a654-ddd11bd284bb" x1="1549.93" y1="543.54" x2="1492.44" y2="486.05"
                        xlink="#f029bd49-42a5-451a-8180-ed5960183037"></linearGradient>
                </defs>
                <title></title>
                <g id="c08d6858-067a-475a-8ac2-70c16c12662b" data-name="Ð¡Ð»Ð¾Ð¹ 2">
                    <g id="7d138c22-65e2-4cd1-a90f-99d7e54013f5" data-name="1">
                        <path class="line_style element_0" d="M0,515.73L319.2,515.73L396,469.53"></path>
                        <path class="line_style element_1" d="M460.5,429.48L581.1,350.88"></path>
                        <path class="line_style element_2" d="M641.71,350.88L767.11,479.88"></path>
                        <path class="line_style element_3" d="M812.71,475.68L955.51,289.08"></path>
                        <path class="line_style element_4" d="M1005.91,242.28L1128.31,164.28"></path>
                        <path class="line_style element_5" d="M1172.11,177.48L1321.51,435.48"></path>
                        <path class="line_style element_6" d="M1369.51,471.48L1487.71,505.68"></path>
                        <path class="line_style element_7" d="M1561.83,514.8L1920,514.8"></path>

                        <path class="text element_8"
                            d="M345.23,565.12a9.24,9.24,0,0,1-4-.87,9.92,9.92,0,0,1-3.11-2.28,10.38,10.38,0,0,1-2-3.23,10.19,10.19,0,0,1-.71-3.75,9.92,9.92,0,0,1,.74-3.78,10,10,0,0,1,5.22-5.42,9.51,9.51,0,0,1,4-.83,9.26,9.26,0,0,1,4,.87,9.85,9.85,0,0,1,3.11,2.3,10.36,10.36,0,0,1,2,3.25,10.23,10.23,0,0,1,.7,3.7,9.92,9.92,0,0,1-.74,3.78,10.06,10.06,0,0,1-5.19,5.43A9.37,9.37,0,0,1,345.23,565.12ZM340,555a7.43,7.43,0,0,0,.34,2.23,6,6,0,0,0,1,1.92,4.89,4.89,0,0,0,1.64,1.34,4.83,4.83,0,0,0,2.24.5,4.74,4.74,0,0,0,2.3-.53,5,5,0,0,0,1.62-1.39,6.06,6.06,0,0,0,1-1.93,7.65,7.65,0,0,0,.32-2.2,7.44,7.44,0,0,0-.34-2.23,5.65,5.65,0,0,0-1-1.9,5,5,0,0,0-1.64-1.32,4.86,4.86,0,0,0-2.23-.49,4.76,4.76,0,0,0-2.28.52,5.1,5.1,0,0,0-1.62,1.36,5.74,5.74,0,0,0-1,1.92A7.64,7.64,0,0,0,340,555Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_9"
                            d="M364.8,549.3a4.44,4.44,0,0,0-2.42,1.27,5.27,5.27,0,0,0-1.22,2.81,5.43,5.43,0,0,1,2-1.54,6.58,6.58,0,0,1,2.8-.56,6.12,6.12,0,0,1,2.49.52,6.64,6.64,0,0,1,2.07,1.43,6.79,6.79,0,0,1,1.41,2.13,6.55,6.55,0,0,1,.52,2.59,7.68,7.68,0,0,1-.52,2.81,6.78,6.78,0,0,1-1.5,2.32,6.94,6.94,0,0,1-2.44,1.57,9,9,0,0,1-3.3.57,7.23,7.23,0,0,1-5.77-2.25q-2-2.25-2-6.59a17.89,17.89,0,0,1,.49-4.48,9.16,9.16,0,0,1,1.46-3.18,7.23,7.23,0,0,1,2.44-2.09,13.07,13.07,0,0,1,3.46-1.2l5.88-1.2.14,3.81Zm-3.22,8.6a3.74,3.74,0,0,0,.88,2.6,2.93,2.93,0,0,0,2.28,1,2.87,2.87,0,0,0,2.24-1,3.65,3.65,0,0,0,.87-2.53,3,3,0,0,0-.88-2.21,3.42,3.42,0,0,0-4.52,0A2.87,2.87,0,0,0,361.58,557.9Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_10"
                            d="M373.87,557.59a8,8,0,0,1,.53-2.9,7.2,7.2,0,0,1,1.54-2.44,7.28,7.28,0,0,1,2.48-1.67,8.59,8.59,0,0,1,3.35-.62,8,8,0,0,1,4.28,1.08,6.92,6.92,0,0,1,2.6,2.81l-4.37,1.32a2.81,2.81,0,0,0-2.55-1.4,3,3,0,0,0-2.34,1,4,4,0,0,0-.94,2.77,4.73,4.73,0,0,0,.25,1.58,3.62,3.62,0,0,0,.7,1.22,3.19,3.19,0,0,0,1.05.78,3,3,0,0,0,1.27.28,3,3,0,0,0,1.54-.41,2.6,2.6,0,0,0,1-1.05l4.37,1.34a6.59,6.59,0,0,1-2.59,2.8,8.12,8.12,0,0,1-4.33,1.09,8.35,8.35,0,0,1-3.32-.63,7.62,7.62,0,0,1-2.49-1.68,7.1,7.1,0,0,1-1.55-2.44A8,8,0,0,1,373.87,557.59Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_11"
                            d="M391.2,567.17a3.43,3.43,0,0,0,.91.29,4.81,4.81,0,0,0,.74.07,1.33,1.33,0,0,0,1.08-.55,4.73,4.73,0,0,0,.69-2l-5.6-14.7h4.62l3.42,10.86L400,550.25h4.2l-5.4,16.55a7.37,7.37,0,0,1-2.16,3.5,5.06,5.06,0,0,1-3.3,1.12,6.28,6.28,0,0,1-1.11-.1,4.1,4.1,0,0,1-1-.32Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_12"
                            d="M409.76,550.25l3.64,5.4h1v-5.4h4.59v5.4h1l3.61-5.4h5.07l-5,7.08,5.46,7.62h-5.32l-3.7-5.49h-1.12V565h-4.56v-5.49h-1.09l-3.7,5.49h-5.32l5.4-7.62-5-7.08Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_13"
                            d="M429.25,561.06H430a2.44,2.44,0,0,0,1.08-.32,2.07,2.07,0,0,0,.74-.94,7.52,7.52,0,0,0,.5-1.82q.2-1.15.39-2.94l.5-4.79h10.95v10.81h2.1v7.84h-4V565h-9.16v3.95h-3.86Zm10.47,0v-7h-2.63l-.2,1.65a20.23,20.23,0,0,1-.59,3.08,10.22,10.22,0,0,1-.92,2.24Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_14"
                            d="M455.46,565.23a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66H452.32a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,455.46,565.23Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_15" d="M469.85,555.49h5v-5.24h4.45V565h-4.45v-5.6h-5V565H465.4v-14.7h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_16" d="M486.9,558.54l6.41-8.29h3.22V565h-4.48v-8l-6.33,8h-3.28v-14.7h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_17"
                            d="M506.7,565.23a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66H503.56a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,506.7,565.23Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_18" d="M385.87,593h-4.45V582.15h-4.54V593h-4.45v-14.7h13.44Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_19"
                            d="M398.35,593.23a5.8,5.8,0,0,1-2.91-.7,4.81,4.81,0,0,1-1.9-2v8.34h-4.48V578.25H393v2.38a5.9,5.9,0,0,1,2-2,5.72,5.72,0,0,1,2.9-.7,6.32,6.32,0,0,1,2.73.59,6.67,6.67,0,0,1,2.16,1.61,7.57,7.57,0,0,1,1.43,2.41,8.45,8.45,0,0,1,.52,3,9.44,9.44,0,0,1-.48,3.05,7.65,7.65,0,0,1-1.32,2.42,6.13,6.13,0,0,1-2,1.61A5.62,5.62,0,0,1,398.35,593.23Zm-1.51-3.78a2.77,2.77,0,0,0,1.33-.32,3.55,3.55,0,0,0,1-.85,3.76,3.76,0,0,0,.69-1.25,4.7,4.7,0,0,0,.24-1.5,4.11,4.11,0,0,0-.27-1.5,3.75,3.75,0,0,0-.73-1.19,3.2,3.2,0,0,0-1.12-.78,3.58,3.58,0,0,0-1.41-.28,3.26,3.26,0,0,0-1.78.55,3.54,3.54,0,0,0-1.3,1.47v3a4.63,4.63,0,0,0,1.36,1.88A3,3,0,0,0,396.84,589.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_20"
                            d="M414,593.23a8.6,8.6,0,0,1-3.35-.62,7.29,7.29,0,0,1-2.48-1.67,7,7,0,0,1-1.53-2.44,8.36,8.36,0,0,1,0-5.8,7,7,0,0,1,1.53-2.44,7.46,7.46,0,0,1,2.48-1.68A8.42,8.42,0,0,1,414,578a8.32,8.32,0,0,1,3.33.63,7.5,7.5,0,0,1,2.46,1.68,7.22,7.22,0,0,1,1.54,2.44,8,8,0,0,1,.53,2.9,8.21,8.21,0,0,1-.52,2.9,7,7,0,0,1-1.53,2.44,7.28,7.28,0,0,1-2.48,1.67A8.6,8.6,0,0,1,414,593.23Zm-3.28-7.62a4.06,4.06,0,0,0,.92,2.79,3,3,0,0,0,2.35,1,3,3,0,0,0,1.29-.28,3.1,3.1,0,0,0,1-.78,3.78,3.78,0,0,0,.69-1.22,4.64,4.64,0,0,0,.25-1.55,4.06,4.06,0,0,0-.92-2.79,3,3,0,0,0-2.32-1,3,3,0,0,0-2.34,1.06,3.75,3.75,0,0,0-.69,1.22A4.64,4.64,0,0,0,410.67,585.62Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_21"
                            d="M431.06,593.23a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66H427.92a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,431.06,593.23Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_22"
                            d="M450.15,578.25h4.79l-5.74,7.11,6.22,7.59h-5.15L446,587.72h-.59V593H441v-14.7h4.42v5.52h.5Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_23" d="M464.4,593H460V582.15h-4.42v-3.89H468.8v3.89h-4.4Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_24"
                            d="M474.62,593.23a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32V593a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,474.62,593.23Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,475.94,590Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_25" d="M553.54,193.55v19.88H549v-8.15H541v8.15h-4.59V193.55H541v7.7H549v-7.7Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_26"
                            d="M561.13,213.71a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46A4.41,4.41,0,0,1,556,209a4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48A7.66,7.66,0,0,1,568.3,200a5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67A6.71,6.71,0,0,1,564,213,6.39,6.39,0,0,1,561.13,213.71Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,562.45,210.49Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_27" d="M586.89,213.43h-4.45V202.62H577.9v10.81h-4.45v-14.7h13.44Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_28" d="M594.54,207l6.41-8.29h3.22v14.7h-4.48v-8l-6.33,8h-3.28v-14.7h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_29"
                            d="M606.44,206.07a8,8,0,0,1,.53-2.9,7.2,7.2,0,0,1,1.54-2.44,7.28,7.28,0,0,1,2.48-1.67,8.59,8.59,0,0,1,3.35-.62,8,8,0,0,1,4.28,1.08,6.92,6.92,0,0,1,2.6,2.81l-4.37,1.32a2.81,2.81,0,0,0-2.55-1.4,3,3,0,0,0-2.34,1,4,4,0,0,0-.94,2.77,4.73,4.73,0,0,0,.25,1.58,3.62,3.62,0,0,0,.7,1.22,3.19,3.19,0,0,0,1.05.78,3,3,0,0,0,1.27.28,3,3,0,0,0,1.54-.41,2.6,2.6,0,0,0,1-1.05l4.37,1.34a6.59,6.59,0,0,1-2.59,2.8,8.12,8.12,0,0,1-4.33,1.09,8.35,8.35,0,0,1-3.32-.63,7.62,7.62,0,0,1-2.49-1.68A7.1,7.1,0,0,1,607,209,8,8,0,0,1,606.44,206.07Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_30"
                            d="M627.43,213.71a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48A7.66,7.66,0,0,1,634.6,200a5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,627.43,213.71Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,628.75,210.49Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_31" d="M644.21,204h5v-5.24h4.45v14.7h-4.45v-5.6h-5v5.6h-4.45v-14.7h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_32" d="M661.26,207l6.41-8.29h3.22v14.7h-4.48v-8l-6.33,8h-3.28v-14.7h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_33"
                            d="M681.05,213.71a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66H677.92a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,681.05,213.71Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_34" d="M612.36,225.58h-6v15.85h-4.59V225.58h-6.08v-4h16.72Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_35"
                            d="M613.51,224.21a9.07,9.07,0,0,1,3-2.11,10.3,10.3,0,0,1,4-.71,7.68,7.68,0,0,1,4.68,1.29,4.44,4.44,0,0,1,1.74,3.81,5.17,5.17,0,0,1-.69,2.6,4.15,4.15,0,0,1-2.34,1.79,4.81,4.81,0,0,1,2.81,1.72,5,5,0,0,1,1,3.09,5.47,5.47,0,0,1-.56,2.51,5.35,5.35,0,0,1-1.55,1.86,7.09,7.09,0,0,1-2.35,1.15,10.52,10.52,0,0,1-2.93.39,11,11,0,0,1-4-.66,10.37,10.37,0,0,1-3.25-2.06l2.35-3.39a8.43,8.43,0,0,0,2.06,1.44,5.85,5.85,0,0,0,2.62.55,3.34,3.34,0,0,0,2.09-.63,2.23,2.23,0,0,0,.83-1.89,2.37,2.37,0,0,0-.77-1.79,3.53,3.53,0,0,0-2.48-.73H617.4v-3.19h2.07a4.25,4.25,0,0,0,2.41-.56,1.82,1.82,0,0,0,.81-1.6,1.7,1.7,0,0,0-.59-1.39,2.81,2.81,0,0,0-1.82-.49,5.74,5.74,0,0,0-2.39.48,7,7,0,0,0-2,1.37Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_36"
                            d="M732.05,613.6l9.35-12.74h3.42v19.88h-4.59V608.31L731,620.74h-3.56V600.86h4.62Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_37"
                            d="M747.65,607.67a8.44,8.44,0,0,1,2.65-1.44,9.11,9.11,0,0,1,2.84-.46,7.55,7.55,0,0,1,1.92.24,5.16,5.16,0,0,1,1.61.71,3.6,3.6,0,0,1,1.12,1.22,3.69,3.69,0,0,1-.2,3.75,2.63,2.63,0,0,1-1.74,1.13,4.17,4.17,0,0,1,2.35,1.39,3.75,3.75,0,0,1,.78,2.39,3.56,3.56,0,0,1-.46,1.79,4.48,4.48,0,0,1-1.26,1.4,6,6,0,0,1-1.9.91A8.44,8.44,0,0,1,753,621a10,10,0,0,1-3.4-.56,5.77,5.77,0,0,1-2.42-1.54l2-2.86a3.16,3.16,0,0,0,1.32.92,4.85,4.85,0,0,0,1.82.34,3.28,3.28,0,0,0,1.69-.38,1.26,1.26,0,0,0,.63-1.16,1.42,1.42,0,0,0-.63-1.25,4.43,4.43,0,0,0-2.34-.43h-1.26v-2.32h1.2a3.44,3.44,0,0,0,2-.46,1.4,1.4,0,0,0,.66-1.19,1,1,0,0,0-.46-.91,2.19,2.19,0,0,0-1.19-.29,4.8,4.8,0,0,0-1.64.32,4.88,4.88,0,0,0-1.55.91Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_38"
                            d="M761.48,623a3.43,3.43,0,0,0,.91.29,4.81,4.81,0,0,0,.74.07,1.33,1.33,0,0,0,1.08-.55,4.73,4.73,0,0,0,.69-2l-5.6-14.7h4.62l3.42,10.86L770.27,606h4.2l-5.4,16.55a7.37,7.37,0,0,1-2.16,3.5,5.06,5.06,0,0,1-3.3,1.12,6.28,6.28,0,0,1-1.11-.1,4.1,4.1,0,0,1-1-.32Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_39"
                            d="M787.94,620.74h-4.37v-4.93a10,10,0,0,1-1.39.39,7.78,7.78,0,0,1-1.55.14,5.61,5.61,0,0,1-2.16-.39,4.56,4.56,0,0,1-2.62-2.7,5.67,5.67,0,0,1-.35-2V606h4.26v4.56a2.53,2.53,0,0,0,.5,1.75,2,2,0,0,0,1.51.55,3.21,3.21,0,0,0,1.79-.48V606h4.37Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_40"
                            d="M798.1,621a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62A7.32,7.32,0,0,1,803.9,608a7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66H795A3,3,0,0,0,796,617a3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,798.1,621Zm3.11-9a3.37,3.37,0,0,0-1-2.21A3,3,0,0,0,798,609a3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_41" d="M812.49,611.28h5V606h4.45v14.7h-4.45v-5.6h-5v5.6H808V606h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_42" d="M829.54,614.33,836,606h3.22v14.7H834.7v-8l-6.33,8h-3.28V606h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_43"
                            d="M849.34,621a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66H846.2a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,849.34,621Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_44"
                            d="M734.22,644.85H735a2.44,2.44,0,0,0,1.08-.32,2.07,2.07,0,0,0,.74-.94,7.52,7.52,0,0,0,.5-1.82q.2-1.15.39-2.94l.5-4.79h10.95v10.81h2.1v7.84h-4v-3.95h-9.16v3.95h-3.86Zm10.47,0v-7h-2.63l-.2,1.65a20.23,20.23,0,0,1-.59,3.08,10.22,10.22,0,0,1-.92,2.24Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_45" d="M758.08,642.33l6.41-8.29h3.22v14.7h-4.48v-8l-6.33,8h-3.28V634h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_46"
                            d="M770.14,635.67a8.44,8.44,0,0,1,2.65-1.44,9.11,9.11,0,0,1,2.84-.46,7.55,7.55,0,0,1,1.92.24,5.16,5.16,0,0,1,1.61.71,3.6,3.6,0,0,1,1.12,1.22,3.69,3.69,0,0,1-.2,3.75,2.63,2.63,0,0,1-1.74,1.13,4.17,4.17,0,0,1,2.35,1.39,3.75,3.75,0,0,1,.78,2.39,3.56,3.56,0,0,1-.46,1.79,4.48,4.48,0,0,1-1.26,1.4,6,6,0,0,1-1.9.91,8.44,8.44,0,0,1-2.37.32,10,10,0,0,1-3.4-.56,5.77,5.77,0,0,1-2.42-1.54l2-2.86A3.16,3.16,0,0,0,773,645a4.85,4.85,0,0,0,1.82.34,3.28,3.28,0,0,0,1.69-.38,1.26,1.26,0,0,0,.63-1.16,1.42,1.42,0,0,0-.63-1.25,4.43,4.43,0,0,0-2.34-.43h-1.26v-2.32h1.2a3.44,3.44,0,0,0,2-.46,1.4,1.4,0,0,0,.66-1.19,1,1,0,0,0-.46-.91,2.19,2.19,0,0,0-1.19-.29,4.8,4.8,0,0,0-1.64.32,4.88,4.88,0,0,0-1.55.91Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_47"
                            d="M787.81,649a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,787.81,649Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,789.13,645.8Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_48"
                            d="M804.58,642.33,811,634h3.22v14.7h-4.48v-8l-6.33,8h-3.28V634h4.45Zm7.64-14a3.82,3.82,0,0,1-1.34,3,5.85,5.85,0,0,1-6.89,0,3.9,3.9,0,0,1-1.37-3H805a2.15,2.15,0,0,0,.64,1.57,2.5,2.5,0,0,0,1.79.59,2.44,2.44,0,0,0,1.72-.56,2.35,2.35,0,0,0,.69-1.6Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_49" d="M821.86,639.28h5V634h4.45v14.7h-4.45v-5.6h-5v5.6h-4.45V634h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_50"
                            d="M838.57,649a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,838.57,649Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,839.89,645.8Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_51"
                            d="M914.21,136.75a12.81,12.81,0,0,1-3.75-1,8.16,8.16,0,0,1-2.66-1.85,7.27,7.27,0,0,1-1.58-2.63,10.58,10.58,0,0,1,0-6.5,7.46,7.46,0,0,1,1.54-2.65,8.2,8.2,0,0,1,2.65-1.9,12.23,12.23,0,0,1,3.82-1v-2.69h4.37v2.69a11.78,11.78,0,0,1,3.78,1,8.26,8.26,0,0,1,2.62,1.92,7.6,7.6,0,0,1,1.53,2.63A9.87,9.87,0,0,1,927,128a8.46,8.46,0,0,1-2.09,5.89,9.76,9.76,0,0,1-6.34,2.81v2.44h-4.37Zm0-13.27a5.14,5.14,0,0,0-3.12,1.47,4.46,4.46,0,0,0-1,3,4.6,4.6,0,0,0,1,3,4.94,4.94,0,0,0,3.14,1.53Zm4.37,9a4.9,4.9,0,0,0,3.05-1.54,4.63,4.63,0,0,0,.92-3,4.56,4.56,0,0,0-.94-3,4.91,4.91,0,0,0-3-1.48Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_52"
                            d="M938.51,138.51a5.8,5.8,0,0,1-2.91-.7,4.81,4.81,0,0,1-1.9-2v8.34h-4.48V123.53h3.89v2.38a5.9,5.9,0,0,1,2-2,5.72,5.72,0,0,1,2.9-.7,6.32,6.32,0,0,1,2.73.59,6.67,6.67,0,0,1,2.16,1.61,7.57,7.57,0,0,1,1.43,2.41,8.45,8.45,0,0,1,.52,3,9.44,9.44,0,0,1-.48,3.05,7.65,7.65,0,0,1-1.32,2.42,6.13,6.13,0,0,1-2,1.61A5.62,5.62,0,0,1,938.51,138.51ZM937,134.73a2.77,2.77,0,0,0,1.33-.32,3.55,3.55,0,0,0,1-.85,3.76,3.76,0,0,0,.69-1.25,4.7,4.7,0,0,0,.24-1.5,4.11,4.11,0,0,0-.27-1.5,3.75,3.75,0,0,0-.73-1.19,3.2,3.2,0,0,0-1.12-.78,3.58,3.58,0,0,0-1.41-.28,3.26,3.26,0,0,0-1.78.55,3.54,3.54,0,0,0-1.3,1.47v3a4.63,4.63,0,0,0,1.36,1.88A3,3,0,0,0,937,134.73Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_53"
                            d="M954.11,138.51a8.6,8.6,0,0,1-3.35-.62,7.29,7.29,0,0,1-2.48-1.67,7,7,0,0,1-1.53-2.44,8.36,8.36,0,0,1,0-5.8,7,7,0,0,1,1.53-2.44,7.46,7.46,0,0,1,2.48-1.68,8.42,8.42,0,0,1,3.35-.63,8.32,8.32,0,0,1,3.33.63,7.5,7.5,0,0,1,2.46,1.68,7.22,7.22,0,0,1,1.54,2.44,8,8,0,0,1,.53,2.9,8.21,8.21,0,0,1-.52,2.9,7,7,0,0,1-1.53,2.44,7.28,7.28,0,0,1-2.48,1.67A8.6,8.6,0,0,1,954.11,138.51Zm-3.28-7.62a4.06,4.06,0,0,0,.92,2.79,3,3,0,0,0,2.35,1,3,3,0,0,0,1.29-.28,3.1,3.1,0,0,0,1-.78,3.78,3.78,0,0,0,.69-1.22,4.64,4.64,0,0,0,.25-1.55,4.06,4.06,0,0,0-.92-2.79,3,3,0,0,0-2.32-1,3,3,0,0,0-2.34,1.06,3.75,3.75,0,0,0-.69,1.22A4.64,4.64,0,0,0,950.83,130.89Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_54" d="M968.7,128.77h5v-5.24h4.45v14.7h-4.45v-5.6h-5v5.6h-4.45v-14.7h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_55" d="M989,138.23h-4.45V127.42h-4.42v-3.89h13.27v3.89H989Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_56"
                            d="M1001.65,138.51a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67A7.05,7.05,0,0,1,1009,128a8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66H998.52a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,1001.65,138.51Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_57" d="M1016,128.77h5v-5.24h4.45v14.7H1021v-5.6h-5v5.6h-4.45v-14.7H1016Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_58"
                            d="M1027.44,134.34h.76a2.44,2.44,0,0,0,1.08-.32,2.07,2.07,0,0,0,.74-.94,7.52,7.52,0,0,0,.5-1.82q.2-1.15.39-2.94l.5-4.79h10.95v10.81h2.1v7.84h-4v-3.95h-9.16v3.95h-3.86Zm10.47,0v-7h-2.63l-.2,1.65a20.23,20.23,0,0,1-.59,3.08,10.22,10.22,0,0,1-.92,2.24Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_59"
                            d="M905.41,166.51a5.8,5.8,0,0,1-2.91-.7,4.81,4.81,0,0,1-1.9-2v8.34h-4.48V151.53H900v2.38a5.9,5.9,0,0,1,2-2,5.72,5.72,0,0,1,2.9-.7,6.32,6.32,0,0,1,2.73.59,6.67,6.67,0,0,1,2.16,1.61,7.57,7.57,0,0,1,1.43,2.41,8.45,8.45,0,0,1,.52,3,9.44,9.44,0,0,1-.48,3.05,7.65,7.65,0,0,1-1.32,2.42,6.13,6.13,0,0,1-2,1.61A5.62,5.62,0,0,1,905.41,166.51Zm-1.51-3.78a2.77,2.77,0,0,0,1.33-.32,3.55,3.55,0,0,0,1-.85,3.76,3.76,0,0,0,.69-1.25,4.7,4.7,0,0,0,.24-1.5,4.11,4.11,0,0,0-.27-1.5,3.75,3.75,0,0,0-.73-1.19,3.2,3.2,0,0,0-1.12-.78,3.58,3.58,0,0,0-1.41-.28,3.26,3.26,0,0,0-1.78.55,3.54,3.54,0,0,0-1.3,1.47v3a4.63,4.63,0,0,0,1.36,1.88A3,3,0,0,0,903.89,162.73Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_60"
                            d="M918.17,166.51a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,918.17,166.51Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,919.49,163.29Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_61"
                            d="M929.74,153.15a8.44,8.44,0,0,1,2.65-1.44,9.11,9.11,0,0,1,2.84-.46,7.55,7.55,0,0,1,1.92.24,5.16,5.16,0,0,1,1.61.71,3.6,3.6,0,0,1,1.12,1.22,3.69,3.69,0,0,1-.2,3.75,2.63,2.63,0,0,1-1.74,1.13,4.17,4.17,0,0,1,2.35,1.39,3.75,3.75,0,0,1,.78,2.39,3.56,3.56,0,0,1-.46,1.79,4.48,4.48,0,0,1-1.26,1.4,6,6,0,0,1-1.9.91,8.44,8.44,0,0,1-2.37.32,10,10,0,0,1-3.4-.56,5.77,5.77,0,0,1-2.42-1.54l2-2.86a3.16,3.16,0,0,0,1.32.92,4.85,4.85,0,0,0,1.82.34,3.28,3.28,0,0,0,1.69-.38,1.26,1.26,0,0,0,.63-1.16,1.42,1.42,0,0,0-.63-1.25,4.43,4.43,0,0,0-2.34-.43h-1.26v-2.32h1.2a3.44,3.44,0,0,0,2-.46,1.4,1.4,0,0,0,.66-1.19,1,1,0,0,0-.46-.91,2.19,2.19,0,0,0-1.19-.29,4.8,4.8,0,0,0-1.64.32,4.88,4.88,0,0,0-1.55.91Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_62"
                            d="M952.58,166.51a5.8,5.8,0,0,1-2.91-.7,4.81,4.81,0,0,1-1.9-2v8.34h-4.48V151.53h3.89v2.38a5.9,5.9,0,0,1,2-2,5.72,5.72,0,0,1,2.9-.7,6.32,6.32,0,0,1,2.73.59,6.67,6.67,0,0,1,2.16,1.61,7.57,7.57,0,0,1,1.43,2.41,8.45,8.45,0,0,1,.52,3,9.44,9.44,0,0,1-.48,3.05,7.65,7.65,0,0,1-1.32,2.42,6.13,6.13,0,0,1-2,1.61A5.62,5.62,0,0,1,952.58,166.51Zm-1.51-3.78a2.77,2.77,0,0,0,1.33-.32,3.55,3.55,0,0,0,1-.85,3.76,3.76,0,0,0,.69-1.25,4.7,4.7,0,0,0,.24-1.5,4.11,4.11,0,0,0-.27-1.5,3.75,3.75,0,0,0-.73-1.19,3.2,3.2,0,0,0-1.12-.78,3.58,3.58,0,0,0-1.41-.28,3.26,3.26,0,0,0-1.78.55,3.54,3.54,0,0,0-1.3,1.47v3a4.63,4.63,0,0,0,1.36,1.88A3,3,0,0,0,951.07,162.73Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_63"
                            d="M965.35,166.51a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,965.35,166.51Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,966.67,163.29Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_64"
                            d="M985,150.58a4.44,4.44,0,0,0-2.42,1.27,5.27,5.27,0,0,0-1.22,2.81,5.43,5.43,0,0,1,2-1.54,6.58,6.58,0,0,1,2.8-.56,6.12,6.12,0,0,1,2.49.52,6.64,6.64,0,0,1,2.07,1.43,6.79,6.79,0,0,1,1.41,2.13,6.55,6.55,0,0,1,.52,2.59,7.68,7.68,0,0,1-.52,2.81,6.78,6.78,0,0,1-1.5,2.32,6.94,6.94,0,0,1-2.44,1.57,9,9,0,0,1-3.3.57,7.23,7.23,0,0,1-5.77-2.25q-2-2.25-2-6.59a17.89,17.89,0,0,1,.49-4.48,9.16,9.16,0,0,1,1.46-3.18,7.23,7.23,0,0,1,2.44-2.09,13.07,13.07,0,0,1,3.46-1.2l5.88-1.2.14,3.81Zm-3.22,8.6a3.74,3.74,0,0,0,.88,2.6,2.93,2.93,0,0,0,2.28,1,2.87,2.87,0,0,0,2.24-1,3.65,3.65,0,0,0,.87-2.53,3,3,0,0,0-.88-2.21,3.42,3.42,0,0,0-4.52,0A2.87,2.87,0,0,0,981.76,159.17Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_65"
                            d="M1001.92,166.51a8.6,8.6,0,0,1-3.35-.62,7.29,7.29,0,0,1-2.48-1.67,7,7,0,0,1-1.53-2.44,8.36,8.36,0,0,1,0-5.8,7,7,0,0,1,1.53-2.44,7.46,7.46,0,0,1,2.48-1.68,8.42,8.42,0,0,1,3.35-.63,8.32,8.32,0,0,1,3.33.63,7.5,7.5,0,0,1,2.46,1.68,7.22,7.22,0,0,1,1.54,2.44,8,8,0,0,1,.53,2.9,8.21,8.21,0,0,1-.52,2.9,7,7,0,0,1-1.53,2.44,7.28,7.28,0,0,1-2.48,1.67A8.6,8.6,0,0,1,1001.92,166.51Zm-3.28-7.62a4.06,4.06,0,0,0,.92,2.79,3,3,0,0,0,2.35,1,3,3,0,0,0,1.29-.28,3.1,3.1,0,0,0,1-.78,3.78,3.78,0,0,0,.69-1.22,4.64,4.64,0,0,0,.25-1.55,4.06,4.06,0,0,0-.92-2.79,3,3,0,0,0-2.32-1,3,3,0,0,0-2.34,1.06,3.75,3.75,0,0,0-.69,1.22A4.64,4.64,0,0,0,998.64,158.89Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_66" d="M1019.06,166.23h-4.45V155.42h-4.42v-3.89h13.27v3.89h-4.4Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_67"
                            d="M1034.6,151.53h4.79l-5.74,7.11,6.22,7.59h-5.15l-4.26-5.24h-.59v5.24h-4.42v-14.7h4.42V157h.5Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_68"
                            d="M1045.12,166.51a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,1045.12,166.51Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,1046.44,163.29Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_69"
                            d="M1105.88,4.06h14.87v4H1110.5V11.9h4.82a7.46,7.46,0,0,1,5,1.55,5.47,5.47,0,0,1,1.81,4.38,5.82,5.82,0,0,1-1.74,4.51,7,7,0,0,1-4.9,1.6h-9.63Zm9.1,16a2.55,2.55,0,0,0,1.83-.6,2.19,2.19,0,0,0,.63-1.67,2.21,2.21,0,0,0-.62-1.61,2.48,2.48,0,0,0-1.85-.63h-4.48V20Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_70"
                            d="M1131.33,24.22a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48A7.19,7.19,0,0,1,1128,9.58a8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66h-10.86a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,1131.33,24.22Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_71"
                            d="M1150.42,9.24h4.79l-5.74,7.11,6.22,7.59h-5.15l-4.26-5.24h-.59v5.24h-4.42V9.24h4.42v5.52h.5Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_72"
                            d="M1163.39,24.22a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48A7.19,7.19,0,0,1,1160,9.58a8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66h-10.86a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,1163.39,24.22Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_73" d="M1177.78,14.48h5V9.24h4.45v14.7h-4.45v-5.6h-5v5.6h-4.45V9.24h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_74"
                            d="M1189.18,20h.76a2.44,2.44,0,0,0,1.08-.32,2.07,2.07,0,0,0,.74-.94,7.52,7.52,0,0,0,.5-1.82q.2-1.15.39-2.94l.5-4.79h10.95V20h2.1v7.84h-4V23.94H1193v3.95h-3.86Zm10.47,0v-7H1197l-.2,1.65a20.23,20.23,0,0,1-.59,3.08,10.22,10.22,0,0,1-.92,2.24Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_75"
                            d="M1085.66,52.22a5.8,5.8,0,0,1-2.91-.7,4.81,4.81,0,0,1-1.9-2V57.9h-4.48V37.24h3.89v2.38a5.9,5.9,0,0,1,2-2,5.72,5.72,0,0,1,2.9-.7,6.32,6.32,0,0,1,2.73.59,6.67,6.67,0,0,1,2.16,1.61,7.57,7.57,0,0,1,1.43,2.41,8.45,8.45,0,0,1,.52,3,9.44,9.44,0,0,1-.48,3.05,7.65,7.65,0,0,1-1.32,2.42,6.13,6.13,0,0,1-2,1.61A5.62,5.62,0,0,1,1085.66,52.22Zm-1.51-3.78a2.77,2.77,0,0,0,1.33-.32,3.55,3.55,0,0,0,1-.85,3.76,3.76,0,0,0,.69-1.25,4.7,4.7,0,0,0,.24-1.5,4.11,4.11,0,0,0-.27-1.5,3.75,3.75,0,0,0-.73-1.19,3.2,3.2,0,0,0-1.12-.78,3.58,3.58,0,0,0-1.41-.28,3.26,3.26,0,0,0-1.78.55,3.54,3.54,0,0,0-1.3,1.47v3a4.63,4.63,0,0,0,1.36,1.88A3,3,0,0,0,1084.15,48.44Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_76"
                            d="M1098.43,52.22a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59V46.9a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,1098.43,52.22Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,1099.74,49Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_77"
                            d="M1110,38.86a8.44,8.44,0,0,1,2.65-1.44,9.11,9.11,0,0,1,2.84-.46,7.55,7.55,0,0,1,1.92.24,5.16,5.16,0,0,1,1.61.71,3.6,3.6,0,0,1,1.12,1.22,3.69,3.69,0,0,1-.2,3.75A2.63,2.63,0,0,1,1118.2,44a4.17,4.17,0,0,1,2.35,1.39,3.75,3.75,0,0,1,.78,2.39,3.56,3.56,0,0,1-.46,1.79,4.48,4.48,0,0,1-1.26,1.4,6,6,0,0,1-1.9.91,8.44,8.44,0,0,1-2.37.32,10,10,0,0,1-3.4-.56,5.77,5.77,0,0,1-2.42-1.54l2-2.86a3.16,3.16,0,0,0,1.32.92,4.85,4.85,0,0,0,1.82.34,3.28,3.28,0,0,0,1.69-.38A1.26,1.26,0,0,0,1117,47a1.42,1.42,0,0,0-.63-1.25,4.43,4.43,0,0,0-2.34-.43h-1.26V43h1.2a3.44,3.44,0,0,0,2-.46,1.4,1.4,0,0,0,.66-1.19,1,1,0,0,0-.46-.91,2.19,2.19,0,0,0-1.19-.29,4.8,4.8,0,0,0-1.64.32,4.88,4.88,0,0,0-1.55.91Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_78"
                            d="M1132.84,52.22a5.8,5.8,0,0,1-2.91-.7,4.81,4.81,0,0,1-1.9-2V57.9h-4.48V37.24h3.89v2.38a5.9,5.9,0,0,1,2-2,5.72,5.72,0,0,1,2.9-.7,6.32,6.32,0,0,1,2.73.59,6.67,6.67,0,0,1,2.16,1.61,7.57,7.57,0,0,1,1.43,2.41,8.45,8.45,0,0,1,.52,3,9.44,9.44,0,0,1-.48,3.05A7.65,7.65,0,0,1,1137.4,50a6.13,6.13,0,0,1-2,1.61A5.62,5.62,0,0,1,1132.84,52.22Zm-1.51-3.78a2.77,2.77,0,0,0,1.33-.32,3.55,3.55,0,0,0,1-.85,3.76,3.76,0,0,0,.69-1.25,4.7,4.7,0,0,0,.24-1.5,4.11,4.11,0,0,0-.27-1.5,3.75,3.75,0,0,0-.73-1.19,3.2,3.2,0,0,0-1.12-.78,3.58,3.58,0,0,0-1.41-.28,3.26,3.26,0,0,0-1.78.55,3.54,3.54,0,0,0-1.3,1.47v3a4.63,4.63,0,0,0,1.36,1.88A3,3,0,0,0,1131.33,48.44Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_79"
                            d="M1145.61,52.22a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59V46.9a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,1145.61,52.22Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,1146.92,49Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_80"
                            d="M1165.24,36.29a4.44,4.44,0,0,0-2.42,1.27,5.27,5.27,0,0,0-1.22,2.81,5.43,5.43,0,0,1,2-1.54,6.58,6.58,0,0,1,2.8-.56,6.12,6.12,0,0,1,2.49.52,6.64,6.64,0,0,1,2.07,1.43,6.79,6.79,0,0,1,1.41,2.13,6.55,6.55,0,0,1,.52,2.59,7.68,7.68,0,0,1-.52,2.81,6.78,6.78,0,0,1-1.5,2.32,6.94,6.94,0,0,1-2.44,1.57,9,9,0,0,1-3.3.57,7.23,7.23,0,0,1-5.77-2.25q-2-2.25-2-6.59a17.89,17.89,0,0,1,.49-4.48,9.16,9.16,0,0,1,1.46-3.18,7.23,7.23,0,0,1,2.44-2.09,13.07,13.07,0,0,1,3.46-1.2l5.88-1.2.14,3.81Zm-3.22,8.6a3.74,3.74,0,0,0,.88,2.6,2.93,2.93,0,0,0,2.28,1,2.87,2.87,0,0,0,2.24-1,3.65,3.65,0,0,0,.87-2.53,3,3,0,0,0-.88-2.21,3.42,3.42,0,0,0-4.52,0A2.87,2.87,0,0,0,1162,44.88Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_81"
                            d="M1182.18,52.22a8.6,8.6,0,0,1-3.35-.62,7.29,7.29,0,0,1-2.48-1.67,7,7,0,0,1-1.53-2.44,8.36,8.36,0,0,1,0-5.8,7,7,0,0,1,1.53-2.44,7.46,7.46,0,0,1,2.48-1.68,8.42,8.42,0,0,1,3.35-.63,8.32,8.32,0,0,1,3.33.63,7.5,7.5,0,0,1,2.46,1.68,7.22,7.22,0,0,1,1.54,2.44,8,8,0,0,1,.53,2.9,8.21,8.21,0,0,1-.52,2.9,7,7,0,0,1-1.53,2.44,7.28,7.28,0,0,1-2.48,1.67A8.6,8.6,0,0,1,1182.18,52.22Zm-3.28-7.62a4.06,4.06,0,0,0,.92,2.79,3,3,0,0,0,2.35,1,3,3,0,0,0,1.29-.28,3.1,3.1,0,0,0,1-.78,3.78,3.78,0,0,0,.69-1.22,4.64,4.64,0,0,0,.25-1.55,4.06,4.06,0,0,0-.92-2.79,3,3,0,0,0-2.32-1,3,3,0,0,0-2.34,1.06,3.75,3.75,0,0,0-.69,1.22A4.64,4.64,0,0,0,1178.9,44.6Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_82" d="M1199.31,51.94h-4.45V41.13h-4.42V37.24h13.27v3.89h-4.4Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_83"
                            d="M1214.85,37.24h4.79l-5.74,7.11,6.22,7.59H1215l-4.26-5.24h-.59v5.24h-4.42V37.24h4.42v5.52h.5Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_84"
                            d="M1225.38,52.22a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2A4.57,4.57,0,0,1,1222,44a6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59V46.9a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,1225.38,52.22Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,1226.69,49Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_85" d="M1256.46,563.09h-6v15.85h-4.59V563.09h-6.08v-4h16.72Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_86"
                            d="M1262.2,579.21a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66h-10.86a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,1262.2,579.21Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_87"
                            d="M1271.22,571.57a8,8,0,0,1,.53-2.9,7.2,7.2,0,0,1,1.54-2.44,7.28,7.28,0,0,1,2.48-1.67,8.59,8.59,0,0,1,3.35-.62,8,8,0,0,1,4.28,1.08,6.92,6.92,0,0,1,2.6,2.81l-4.37,1.32a2.81,2.81,0,0,0-2.55-1.4,3,3,0,0,0-2.34,1,4,4,0,0,0-.94,2.77,4.73,4.73,0,0,0,.25,1.58,3.62,3.62,0,0,0,.7,1.22,3.19,3.19,0,0,0,1.05.78,3,3,0,0,0,1.27.28,3,3,0,0,0,1.54-.41,2.6,2.6,0,0,0,1-1.05l4.37,1.34a6.59,6.59,0,0,1-2.59,2.8,8.12,8.12,0,0,1-4.33,1.09,8.35,8.35,0,0,1-3.32-.63,7.62,7.62,0,0,1-2.49-1.68,7.1,7.1,0,0,1-1.55-2.44A8,8,0,0,1,1271.22,571.57Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_88" d="M1295.8,578.93h-4.45V568.13h-4.42v-3.89h13.27v3.89h-4.4Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_89" d="M1306.64,572.52l6.41-8.29h3.22v14.7h-4.48v-8l-6.33,8h-3.28v-14.7h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_90"
                            d="M1328.76,579.21a5.8,5.8,0,0,1-2.91-.7,4.81,4.81,0,0,1-1.9-2v8.34h-4.48V564.23h3.89v2.38a5.9,5.9,0,0,1,2-2,5.72,5.72,0,0,1,2.9-.7,6.32,6.32,0,0,1,2.73.59,6.67,6.67,0,0,1,2.16,1.61,7.57,7.57,0,0,1,1.43,2.41,8.45,8.45,0,0,1,.52,3,9.44,9.44,0,0,1-.48,3.05,7.65,7.65,0,0,1-1.32,2.42,6.13,6.13,0,0,1-2,1.61A5.62,5.62,0,0,1,1328.76,579.21Zm-1.51-3.78a2.77,2.77,0,0,0,1.33-.32,3.55,3.55,0,0,0,1-.85,3.76,3.76,0,0,0,.69-1.25,4.7,4.7,0,0,0,.24-1.5,4.11,4.11,0,0,0-.27-1.5,3.75,3.75,0,0,0-.73-1.19,3.2,3.2,0,0,0-1.12-.78,3.58,3.58,0,0,0-1.41-.28,3.26,3.26,0,0,0-1.78.55,3.54,3.54,0,0,0-1.3,1.47v3a4.63,4.63,0,0,0,1.36,1.88A3,3,0,0,0,1327.24,575.43Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_91"
                            d="M1344.35,579.21a8.6,8.6,0,0,1-3.35-.62,7.29,7.29,0,0,1-2.48-1.67,7,7,0,0,1-1.53-2.44,8.36,8.36,0,0,1,0-5.8,7,7,0,0,1,1.53-2.44,7.46,7.46,0,0,1,2.48-1.68,8.42,8.42,0,0,1,3.35-.63,8.32,8.32,0,0,1,3.33.63,7.5,7.5,0,0,1,2.46,1.68,7.22,7.22,0,0,1,1.54,2.44,8,8,0,0,1,.53,2.9,8.21,8.21,0,0,1-.52,2.9,7,7,0,0,1-1.53,2.44,7.28,7.28,0,0,1-2.48,1.67A8.6,8.6,0,0,1,1344.35,579.21Zm-3.28-7.62a4.06,4.06,0,0,0,.92,2.79,3,3,0,0,0,2.35,1,3,3,0,0,0,1.29-.28,3.1,3.1,0,0,0,1-.78,3.78,3.78,0,0,0,.69-1.22,4.64,4.64,0,0,0,.25-1.55,4.06,4.06,0,0,0-.92-2.79,3,3,0,0,0-2.32-1,3,3,0,0,0-2.34,1.06,3.75,3.75,0,0,0-.69,1.22A4.64,4.64,0,0,0,1341.08,571.6Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_92"
                            d="M1363.92,564.23a3.52,3.52,0,0,1,2.66,1.13,3.79,3.79,0,0,1,.74,1.2,3.85,3.85,0,0,1-.25,3.33,3.23,3.23,0,0,1-1.69,1.41,4.33,4.33,0,0,1,2.09,1.27,3.6,3.6,0,0,1,.77,2.45,3.21,3.21,0,0,1-1.51,3,8,8,0,0,1-4.14.91h-8.09v-14.7Zm-2.18,6a1.5,1.5,0,0,0,1-.35,1.37,1.37,0,0,0,.39-1.08,1.44,1.44,0,0,0-.36-1,1.29,1.29,0,0,0-1-.41H1359v2.83Zm.56,5.43a1.36,1.36,0,0,0,1-.42,1.47,1.47,0,0,0,.41-1.06,1.58,1.58,0,0,0-.39-1.12,1.35,1.35,0,0,0-1-.42H1359v3Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_93"
                            d="M1374.67,579.21a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,1374.67,579.21ZM1376,576a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,1376,576Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_94" d="M1391.45,569.47h5v-5.24h4.45v14.7h-4.45v-5.6h-5v5.6H1387v-14.7h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_95" d="M1408.5,572.52l6.41-8.29h3.22v14.7h-4.48v-8l-6.33,8H1404v-14.7h4.45Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_96"
                            d="M1428.29,579.21a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66h-10.86a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,1428.29,579.21Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_97"
                            d="M1479.52,622.46a10,10,0,0,1,.67-3.57,9.73,9.73,0,0,1,2-3.18,10,10,0,0,1,3.14-2.27,9.77,9.77,0,0,1,4.2-.87,9.45,9.45,0,0,1,4.86,1.2,7.79,7.79,0,0,1,3.07,3.14l-3.53,2.46a4.12,4.12,0,0,0-.85-1.3,4.66,4.66,0,0,0-2.39-1.29,6.56,6.56,0,0,0-1.26-.13,4.75,4.75,0,0,0-2.3.53,5.05,5.05,0,0,0-1.62,1.37,5.7,5.7,0,0,0-1,1.9,7.71,7.71,0,0,0-.31,2.16,7.12,7.12,0,0,0,.36,2.27,6,6,0,0,0,1.05,1.93,5.07,5.07,0,0,0,1.65,1.33,4.72,4.72,0,0,0,2.17.49,5.6,5.6,0,0,0,1.27-.15A5.15,5.15,0,0,0,1492,628a4.51,4.51,0,0,0,1.11-.84,3.94,3.94,0,0,0,.81-1.27l3.75,2.21a5.84,5.84,0,0,1-1.33,2,8.2,8.2,0,0,1-2,1.46,11.15,11.15,0,0,1-5,1.2,8.91,8.91,0,0,1-4-.88,10,10,0,0,1-3.11-2.34,10.7,10.7,0,0,1-2-3.3A10.33,10.33,0,0,1,1479.52,622.46Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_98"
                            d="M1498.25,628.68h.76a2.44,2.44,0,0,0,1.08-.32,2.07,2.07,0,0,0,.74-.94,7.52,7.52,0,0,0,.5-1.82q.2-1.15.39-2.94l.5-4.79h10.95v10.81h2.1v7.84h-4v-3.95h-9.16v3.95h-3.86Zm10.47,0v-7h-2.63l-.2,1.65a20.23,20.23,0,0,1-.59,3.08,10.22,10.22,0,0,1-.92,2.24Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_99"
                            d="M1521.77,632.85a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,1521.77,632.85Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,1523.09,629.63Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_100"
                            d="M1545.74,632.57h-4.37v-4.93a10,10,0,0,1-1.39.39,7.78,7.78,0,0,1-1.55.14,5.61,5.61,0,0,1-2.16-.39,4.56,4.56,0,0,1-2.62-2.7,5.67,5.67,0,0,1-.35-2v-5.21h4.26v4.56a2.53,2.53,0,0,0,.5,1.75,2,2,0,0,0,1.51.55,3.21,3.21,0,0,0,1.79-.48v-6.38h4.37Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_101"
                            d="M1553,632.85a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,1553,632.85Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,1554.36,629.63Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_102" d="M1479,660.57h-4.45V649.76h-4.54v10.81h-4.45v-14.7H1479Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_103"
                            d="M1491.53,660.85a5.8,5.8,0,0,1-2.91-.7,4.81,4.81,0,0,1-1.9-2v8.34h-4.48V645.87h3.89v2.38a5.9,5.9,0,0,1,2-2,5.72,5.72,0,0,1,2.9-.7,6.32,6.32,0,0,1,2.73.59,6.67,6.67,0,0,1,2.16,1.61,7.57,7.57,0,0,1,1.43,2.41,8.45,8.45,0,0,1,.52,3,9.44,9.44,0,0,1-.48,3.05,7.65,7.65,0,0,1-1.32,2.42,6.13,6.13,0,0,1-2,1.61A5.62,5.62,0,0,1,1491.53,660.85Zm-1.51-3.78a2.77,2.77,0,0,0,1.33-.32,3.55,3.55,0,0,0,1-.85,3.76,3.76,0,0,0,.69-1.25,4.7,4.7,0,0,0,.24-1.5,4.11,4.11,0,0,0-.27-1.5,3.75,3.75,0,0,0-.73-1.19,3.2,3.2,0,0,0-1.12-.78,3.58,3.58,0,0,0-1.41-.28,3.26,3.26,0,0,0-1.78.55,3.54,3.54,0,0,0-1.3,1.47v3a4.63,4.63,0,0,0,1.36,1.88A3,3,0,0,0,1490,657.07Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_104"
                            d="M1507.13,660.85a8.6,8.6,0,0,1-3.35-.62,7.29,7.29,0,0,1-2.48-1.67,7,7,0,0,1-1.53-2.44,8.36,8.36,0,0,1,0-5.8,7,7,0,0,1,1.53-2.44,7.46,7.46,0,0,1,2.48-1.68,8.42,8.42,0,0,1,3.35-.63,8.32,8.32,0,0,1,3.33.63,7.5,7.5,0,0,1,2.46,1.68,7.22,7.22,0,0,1,1.54,2.44,8,8,0,0,1,.53,2.9,8.21,8.21,0,0,1-.52,2.9,7,7,0,0,1-1.53,2.44,7.28,7.28,0,0,1-2.48,1.67A8.6,8.6,0,0,1,1507.13,660.85Zm-3.28-7.62a4.06,4.06,0,0,0,.92,2.79,3,3,0,0,0,2.35,1,3,3,0,0,0,1.29-.28,3.1,3.1,0,0,0,1-.78,3.78,3.78,0,0,0,.69-1.22,4.64,4.64,0,0,0,.25-1.55,4.06,4.06,0,0,0-.92-2.79,3,3,0,0,0-2.32-1,3,3,0,0,0-2.34,1.06,3.75,3.75,0,0,0-.69,1.22A4.64,4.64,0,0,0,1503.85,653.23Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_105"
                            d="M1524.24,660.85a8.69,8.69,0,0,1-3.32-.6,7.37,7.37,0,0,1-2.49-1.64,7,7,0,0,1-1.55-2.39,7.72,7.72,0,0,1-.53-2.84,8.47,8.47,0,0,1,.53-3,7.3,7.3,0,0,1,1.54-2.48,7.19,7.19,0,0,1,2.48-1.68,8.59,8.59,0,0,1,3.35-.62,8.49,8.49,0,0,1,3.33.62,7.32,7.32,0,0,1,2.46,1.67,7.05,7.05,0,0,1,1.53,2.42,8.1,8.1,0,0,1,.52,2.88q0,.39,0,.77a5.58,5.58,0,0,1-.08.66H1521.1a3,3,0,0,0,1.08,2.25,3.43,3.43,0,0,0,2.2.77,4,4,0,0,0,1.89-.48,2.43,2.43,0,0,0,1.22-1.29l3.81,1.06a6.86,6.86,0,0,1-2.69,2.83A8.34,8.34,0,0,1,1524.24,660.85Zm3.11-9a3.37,3.37,0,0,0-1-2.21,3,3,0,0,0-2.16-.81,3,3,0,0,0-2.17.83,3.43,3.43,0,0,0-1,2.2Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_106"
                            d="M1543.33,645.87h4.79l-5.74,7.11,6.22,7.59h-5.15l-4.26-5.24h-.59v5.24h-4.42v-14.7h4.42v5.52h.5Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_107" d="M1557.58,660.57h-4.45V649.76h-4.42v-3.89H1562v3.89h-4.4Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="text element_108"
                            d="M1567.8,660.85a5.77,5.77,0,0,1-2-.35,4.64,4.64,0,0,1-2.67-2.46,4.41,4.41,0,0,1-.39-1.86,4.27,4.27,0,0,1,.46-2,4.57,4.57,0,0,1,1.3-1.54,6.44,6.44,0,0,1,2-1,8.41,8.41,0,0,1,2.55-.36,10.31,10.31,0,0,1,1.79.15,5.91,5.91,0,0,1,1.51.46v-.67q0-2.55-2.94-2.55a6.55,6.55,0,0,0-2.3.41,11.06,11.06,0,0,0-2.3,1.22l-1.32-2.8a11.76,11.76,0,0,1,3-1.43,11.41,11.41,0,0,1,3.33-.48,7.66,7.66,0,0,1,5.15,1.57,5.73,5.73,0,0,1,1.82,4.59v3.78a1.56,1.56,0,0,0,.21.94,1,1,0,0,0,.74.32v3.78a7.54,7.54,0,0,1-1.06.18q-.48,0-.87,0a3.11,3.11,0,0,1-1.9-.49,2.16,2.16,0,0,1-.81-1.39l-.08-.67a6.71,6.71,0,0,1-2.34,1.93A6.39,6.39,0,0,1,1567.8,660.85Zm1.32-3.22a4.07,4.07,0,0,0,1.41-.25,3.3,3.3,0,0,0,1.13-.67,1.39,1.39,0,0,0,.64-1.06v-1.4a7.27,7.27,0,0,0-1.27-.35,7.08,7.08,0,0,0-1.27-.13,3.45,3.45,0,0,0-2,.56,1.71,1.71,0,0,0-.8,1.46,1.66,1.66,0,0,0,.62,1.33A2.35,2.35,0,0,0,1569.12,657.63Z"
                            transform="translate(0 -4.06)"></path>

                        <path class="transparent element_109"
                            d="M381.3,450.33A48.9,48.9 0,1,1 479.09999999999997,450.33A48.9,48.9 0,1,1 381.3,450.33"></path>
                        <path class="circle_1 element_110"
                            d="M389.55,450.33A40.65,40.65 0,1,1 470.84999999999997,450.33A40.65,40.65 0,1,1 389.55,450.33"></path>
                        <path class="number_style element_111"
                            d="M441,462.18v6.91H420.31v-6.91h6.82V449.94a5.33,5.33,0,0,1-1.34,1.18,14.28,14.28,0,0,1-2,1.1,15.15,15.15,0,0,1-2.3.82,8.24,8.24,0,0,1-2.09.31v-7.1a5.33,5.33,0,0,0,2-.53,18.44,18.44,0,0,0,2.42-1.27,22.08,22.08,0,0,0,2.16-1.51,4.55,4.55,0,0,0,1.2-1.25H435v20.5Z"
                            transform="translate(0 -4.06)"></path>

                        <path class="transparent element_112"
                            d="M562.8000000000001,329.28A48.9,48.9 0,1,1 660.6,329.28A48.9,48.9 0,1,1 562.8000000000001,329.28">
                        </path>
                        <path class="circle_2 element_113"
                            d="M571.0500000000001,329.28A40.65,40.65 0,1,1 652.35,329.28A40.65,40.65 0,1,1 571.0500000000001,329.28">
                        </path>
                        <path class="number_style element_114"
                            d="M600.71,348a21.81,21.81,0,0,1,.34-4,10.67,10.67,0,0,1,1.15-3.24,10.56,10.56,0,0,1,2.21-2.71,20.22,20.22,0,0,1,3.5-2.45l3.77-2.06a24.59,24.59,0,0,0,2.4-1.44,5.84,5.84,0,0,0,1.27-1.13,1.89,1.89,0,0,0,.38-1.18,2.7,2.7,0,0,0-1-2.16,4.11,4.11,0,0,0-2.74-.82,6.21,6.21,0,0,0-3.5,1,14.59,14.59,0,0,0-2.88,2.57l-4.9-5.66a9.56,9.56,0,0,1,2.06-1.68,17.86,17.86,0,0,1,2.93-1.46,20.43,20.43,0,0,1,3.58-1,20.94,20.94,0,0,1,4.06-.38q5.18,0,7.94,2.26a8.14,8.14,0,0,1,2.26,8.93,8.38,8.38,0,0,1-1.32,2.23,10.34,10.34,0,0,1-1.82,1.73,24.14,24.14,0,0,1-2.06,1.37l-1.73,1q-1,.58-2,1.18t-1.82,1.2a8,8,0,0,0-1.27,1.08h13.06V348Z"
                            transform="translate(0 -4.06)"></path>

                        <path class="transparent element_115"
                            d="M743.41,505.68A48.9,48.9 0,1,1 841.2099999999999,505.68A48.9,48.9 0,1,1 743.41,505.68"></path>
                        <path class="circle_3 element_116"
                            d="M751.66,505.68A40.65,40.65 0,1,1 832.9599999999999,505.68A40.65,40.65 0,1,1 751.66,505.68"></path>
                        <path class="number_style element_117"
                            d="M798.31,509.95a8.43,8.43,0,0,1,2.74,1,7.75,7.75,0,0,1,2.14,1.87,8.38,8.38,0,0,1,1.37,2.59,10.05,10.05,0,0,1,.48,3.14,8.85,8.85,0,0,1-.91,4.06,8.74,8.74,0,0,1-2.62,3.07,12.73,12.73,0,0,1-4.1,2,19.31,19.31,0,0,1-5.38.7q-7.3,0-11.42-4.27l4.13-5.47a8.8,8.8,0,0,0,3,2,10.18,10.18,0,0,0,4,.7q5.42,0,5.42-4a3.7,3.7,0,0,0-1.61-3.26,9.11,9.11,0,0,0-5.06-1.1h-1.44V507.5h1.58a7,7,0,0,0,4.2-1.1,3.45,3.45,0,0,0,1.51-2.93,2.8,2.8,0,0,0-1.1-2.4,4.81,4.81,0,0,0-2.93-.82,7,7,0,0,0-3.58.94,8,8,0,0,0-2.71,2.66l-4.85-5.57a8.77,8.77,0,0,1,2-1.87,13.13,13.13,0,0,1,2.83-1.46,19.76,19.76,0,0,1,3.41-.94,20.7,20.7,0,0,1,3.74-.34,17,17,0,0,1,4.66.6,10.78,10.78,0,0,1,3.6,1.73,8,8,0,0,1,2.3,2.69,7.4,7.4,0,0,1,.82,3.48,8.19,8.19,0,0,1-.46,2.74,8.36,8.36,0,0,1-1.27,2.35,7.25,7.25,0,0,1-1.94,1.75A7,7,0,0,1,798.31,509.95Z"
                            transform="translate(0 -4.06)"></path>

                        <path class="transparent element_118"
                            d="M926.41,262.08A48.9,48.9 0,1,1 1024.21,262.08A48.9,48.9 0,1,1 926.41,262.08"></path>
                        <path class="circle_4 element_119"
                            d="M934.66,262.08A40.65,40.65 0,1,1 1015.9599999999999,262.08A40.65,40.65 0,1,1 934.66,262.08"></path>
                        <path class="number_style element_120"
                            d="M975.09,283.25v-6.58h-13.2v-6.86l15.65-20.06h5.23v20H987v6.91h-4.22v6.58Zm-5.62-13.49H976v-8.5Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="transparent element_121"
                            d="M1108.81,148.68A48.9,48.9 0,1,1 1206.6100000000001,148.68A48.9,48.9 0,1,1 1108.81,148.68"></path>
                        <path class="circle_5 element_122"
                            d="M1117.06,148.68A40.65,40.65 0,1,1 1198.3600000000001,148.68A40.65,40.65 0,1,1 1117.06,148.68"></path>
                        <path class="number_style element_123"
                            d="M1157.54,148.3a12.17,12.17,0,0,1,4.51.82,11,11,0,0,1,3.58,2.26,10,10,0,0,1,2.35,3.46,12.2,12.2,0,0,1-.12,9.19,10.91,10.91,0,0,1-2.66,3.67,12,12,0,0,1-4.06,2.38,15.33,15.33,0,0,1-5.18.84,14.83,14.83,0,0,1-6.72-1.49,10.39,10.39,0,0,1-4.51-4.18l4.27-4.75a11,11,0,0,0,3.24,2.69,7.78,7.78,0,0,0,3.77,1,5,5,0,0,0,3.6-1.3,4.58,4.58,0,0,0,1.34-3.46,4.48,4.48,0,0,0-1.25-3.31,4.41,4.41,0,0,0-3.26-1.25,5.11,5.11,0,0,0-2.4.58,4.58,4.58,0,0,0-1.78,1.63h-6.67s.06-.36.19-1,.28-1.43.46-2.42.38-2.1.62-3.34.49-2.49.74-3.77q.86-4.51,2-10.22h16.94v7h-11.38l-1,5.81a2.5,2.5,0,0,1,1.13-.6A7.68,7.68,0,0,1,1157.54,148.3Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="transparent element_124"
                            d="M1289.4099999999999,463.68A48.9,48.9 0,1,1 1387.21,463.68A48.9,48.9 0,1,1 1289.4099999999999,463.68">
                        </path>
                        <path class="circle_6 element_125"
                            d="M1297.6599999999999,463.68A40.65,40.65 0,1,1 1378.96,463.68A40.65,40.65 0,1,1 1297.6599999999999,463.68">
                        </path>
                        <path class="number_style element_126"
                            d="M1351.05,474.09a11.5,11.5,0,0,1-1,4.68,11.72,11.72,0,0,1-2.64,3.79,12.49,12.49,0,0,1-4,2.54,13.14,13.14,0,0,1-5,.94,12.4,12.4,0,0,1-5.33-1.1,10.94,10.94,0,0,1-4-3.17,14.56,14.56,0,0,1-2.5-5.06,24.75,24.75,0,0,1-.86-6.84,30.66,30.66,0,0,1,.94-7.94,17.66,17.66,0,0,1,2.66-5.86,11.59,11.59,0,0,1,4.22-3.62,12.38,12.38,0,0,1,5.62-1.25,13.46,13.46,0,0,1,5.88,1.3,14.26,14.26,0,0,1,4.78,3.74l-4.32,4.9a8.64,8.64,0,0,0-2.88-2.4,7.76,7.76,0,0,0-3.6-.86,5.5,5.5,0,0,0-4.49,2,9.16,9.16,0,0,0-1.75,5.71,6.34,6.34,0,0,1,2.57-2,9,9,0,0,1,3.72-.72,12.74,12.74,0,0,1,4.73.86,11.54,11.54,0,0,1,3.77,2.38,11.16,11.16,0,0,1,2.5,3.55A10.77,10.77,0,0,1,1351.05,474.09Zm-12.58,5.47a4.86,4.86,0,0,0,2-.43,5.38,5.38,0,0,0,1.68-1.18,5.75,5.75,0,0,0,1.15-1.75,5.3,5.3,0,0,0,.43-2.11,4.86,4.86,0,0,0-.43-2,5.31,5.31,0,0,0-1.15-1.66,5.49,5.49,0,0,0-1.68-1.1,5.11,5.11,0,0,0-2-.41,5.23,5.23,0,0,0-2.06.41,5.48,5.48,0,0,0-1.68,1.1,5,5,0,0,0-1.13,1.66,5.11,5.11,0,0,0-.41,2,5.31,5.31,0,0,0,.43,2.11,5.75,5.75,0,0,0,1.15,1.75,5.38,5.38,0,0,0,1.68,1.18A4.86,4.86,0,0,0,1338.48,479.56Z"
                            transform="translate(0 -4.06)"></path>
                        <path class="transparent element_127"
                            d="M1472.28,514.8A48.9,48.9 0,1,1 1570.0800000000002,514.8A48.9,48.9 0,1,1 1472.28,514.8"></path>
                        <path class="circle_7 element_128"
                            d="M1480.53,514.8A40.65,40.65 0,1,1 1561.8300000000002,514.8A40.65,40.65 0,1,1 1480.53,514.8"></path>
                        <path class="number_style element_129" d="M1523.5,511.41h-14.35v-7h26.45l-16.8,34.08h-8.69Z"
                            transform="translate(0 -4.06)"></path>
                    </g>
                </g>
                <style>

                </style>
            </svg>
        </div>
    )
}

export default steps;