import React, { useEffect } from 'react';
import { ProjectButtons } from '../../ProjectButtons';

export const SportBet = () => {
  useEffect(() => {
    import('../assets/js/additional.min.js')
      .then(() => import('../assets/js/main.min.js')
        .then(() => import('../assets/js/maxline.min.js')))
  }, [])

  return (
    <div>
      <div class="content">
        <div class="portfolio-slider srpbt">
          <div class="item">
            <div class="bg-stellar" style={{ background: 'url("/img/background/maxline.png") center center no-repeat', backgroundSize: 'cover' }}>
              <img src="/img/maxline/lebron.png" alt="Lebron" class="lebron viewport_animation" data-vp-add-class="animated fadeInLeft" />
              <img src="/img/maxline/nadal.png" alt="Nadal" class="nadal viewport_animation" data-vp-add-class="animated fadeInRight" />
              <img src="/img/maxline/ovechkin.png" alt="Ovechkin" class="ovechkin viewport_animation" data-vp-add-class="animated fadeInRight" />
              <img src="/img/maxline/neymar.png" alt="Neymar" class="neymar viewport_animation" data-vp-add-class="animated fadeInLeft" />
            </div>
            <div class="inner-text">
              <div
                class="border">
                <h1 class=" viewport_animation" data-vp-add-class="animated fadeInRight">Букмекерская компания</h1>
                <h1 class=" viewport_animation" style={{ marginTop: '20px' }} data-vp-add-class="animated fadeInRight">
                  SportBet</h1>
                <p class=" viewport_animation" data-vp-add-class="animated fadeInRight"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="portfolio prtolio-height" id="portfolio">
          <div class="conf-screen">
            <img class="left viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/maxline/screen_2.png" alt="Левый" />
            <img class="center viewport_animation" data-vp-add-class="animated fadeInUp" src="/img/maxline/screen_1.png" alt="Центральный" />
            <img class="right viewport_animation" data-vp-add-class="animated fadeInRight" src="/img/maxline/screen_3.png" alt="Правый" />
          </div>
          <div class="task">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Задача</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Готовая платформа для работы
                букмекеров с дальнейшим сопровождением</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Наша онлайн-платформа для приёма
                ставок в режиме онлайн - оптимальное решение и гарант успеха для операторов iGaming.

                Платформа разрабатывалась для крупной букмекерской компании, поэтому полностью закрывает потребности
                гэмблинг-бизнеса. В ней реализованы ручной и авторасчёт ставок, полное ведение букмекером линий Live
                и Prematch, а также формируется вся необходимая отчётность.

                Данное программное обеспечение уже длительное время выдерживает высокий трафик на сайте. Мы
                проработали структуру таким образом, чтобы ПО сохраняло устойчивость к большим нагрузкам,
                поддерживая при этом быстроту отклика.
              </p>
            </div>
          </div>
          <div class="maxline-line -gray">
            <div class="container">
              <div class="column">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  — Основной
                  функционал</span>
                <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Преимущества решения</h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Наше ПО сочетает в себе
                  несколько функциональных блоков, которые полностью покрывают запросы букмекеров и менеджеров.
                  Делая выбор в пользу нашей платформы, Вы получаете:

                  современный веб-сайт с функционалом регистрации игроков, ставок на спортивные события, внесение
                  платежей, отображением коэффициентов на различные маркеты событий и др;

                  удобный и практичный backoffice, в котором собран весь функционал для беттинг-администрирования
                  - ведение линий Live и Prematch, управление коэффициентами, мониторинг регистраций и выплат,
                  контроль транзакций, расчёт ставок и др;

                  мониторинг котировок конкурентов через реализованные в ПО парсера данных, что позволяет не
                  только собирать данные по линии, но и проводить последующий анализ. Платформа автоматически
                  приводит данные к общему виду, после чего ими можно управлять через backoffice;

                  управление программами лоянольсти для игроков, которое позволит букмекерам генерировать
                  уникальные бонусные механики, подстраиваи их под различные группы пользователей. Это помогает
                  наращивать базу игроков и удерживать интерес постоянных клиентов, что положительно влияет на
                  доходность букмекерской компании;

                  формирование отчётов через отслеживание бизнес-показателей, таких как GGR, NGGR, CPA, APRU, CAC,
                  ARPC, что помогает бизнесу держать руку на пульсе в анализе доходности букмекерской компании.

                  Bisonix гордится тем, что уделяет особое внимание защите от мошенничества. Мы гарантируем
                  безопасность и надёжность нашей платформы для онлайн-ставок.
                </p>
              </div>
              <div class="column">
                <div class="first-line viewport_animation" data-vp-add-class="animated fadeInUp">
                  <img src="/img/maxline/first-line.png" alt="первая линия" />
                </div>
                <div class="second-line viewport_animation" data-vp-add-class="animated fadeInUp">
                  <img src="/img/maxline/second-line.png" alt="вторая линия" />
                </div>
              </div>
            </div>
          </div>
          <div class="maxline-live">
            <div class="container">
              <div class="column">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  — Ставки в
                  режиме LIVE</span>
                <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Быть в курсе всех идущих
                  событий</h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Кроме основной линии ставок,
                  которую привыкло видеть преобладающее большинство пользователей, когда-либо интересовавшихся
                  ставками на спорт, на сайт был добавлен функционал вывода информации о текущих матчах. В
                  букмекерском бизнесе эта категория ставок носит название «Live» и большинство игроков рынка
                  акцентируют внимание именно на этом функционале. Для удобства вывода информации о спортивных
                  событиях, проходящих в реальном времени было разработано и внедрено множество визуальных
                  идентификаторов, которые позволяют пользователям оперативно находить нужный вид спорта или
                  конкретное событие. Благодаря совокупности всех фильтров, а также понятной и простой навигации
                  по сайту, нам удалось достичь достаточно высоких показателей удобства интерфейса.</p>
                <div class="bet viewport_animation" data-vp-add-class="animated fadeInUp">
                  <span>Событие</span>
                  <h3>БАТЭ - Кёльн</h3>
                  <div class="result">
                    <p>2</p>
                    <p>0</p>
                  </div>
                  <div class="position">
                    <p>1</p>
                    <p>x</p>
                    <p>2</p>
                  </div>
                  <div class="coficient">
                    <p data-coef="1.18">1.18</p>
                    <p data-coef="7.25">7.25</p>
                    <p data-coef="13.56">13.56</p>
                  </div>
                  <h3 id="price-change">1 180 руб.</h3>
                  <span>*Возможный выигрыш при ставке 1 000 руб.</span>
                </div>
              </div>
              <div class="column">
                <div class="live-screen viewport_animation" data-vp-add-class="animated fadeInRight">
                  <img class="live-screen" src="/img/maxline/live-screen.png" />
                </div>
                <div class="tv viewport_animation" data-vp-add-class="animated fadeInUp">
                  <img src="/img/maxline/tv.png" alt="телевизор с празднованием" />
                </div>
              </div>
            </div>
          </div>
          <div class="maxline-user -gray">
            <div class="container">
              <div class="column">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  — Личный кабинет
                  и поддержка</span>
                <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Важен каждый посетитель</h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Для удержания аудитории и
                  трансформации обычного посетителя сайта в игрока, мы проработали сервис, позволяющий отслеживать
                  статус обращения клиентом прямо из его личного кабинета. Таким образом, пользователь может
                  обратиться в службу поддержки и визуально проследить статус этого обращения. Для менеджеров
                  службы поддержки, данная схема взаимодействия позволяет грамотно планировать ресурсы рабочего
                  времени и не упускать должный контроль над критическими ситуациями, которым требуется особый
                  подход.
                </p>
              </div>
              <div class="column">
                <div class="user-screen viewport_animation" data-vp-add-class="animated fadeInRight">
                  <img src="/img/maxline/user-screen.png" alt="экран со ставками" />
                </div>
              </div>
            </div>
          </div>
          <div class="maxline-chart">
            <div class="container">
              <div class="row">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  — Статистика
                  посещений</span>
                <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Главный показатель того, что
                  проект работает - стабильный прирост посещений сайта и сделанных ставок</h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Выбирая сотрудничество с нами,
                  Вы выбираете для своей букмекерской компании стабильный трафик и растущий доход. Для наглядности
                  приводим ниже информацию по посещению нашего сервиса после запуска, представленную в виде
                  графика.
                </p>
                <div class="chart viewport_animation" data-vp-add-class="animated fadeInUp">
                  <div class="chartjs-tooltip" id="tooltip-0"></div>
                  <canvas id="maxline-chart" height="400"></canvas>
                </div>
                <p>
                  Наша платформа для онлайн-ставок идеально подойдёт для запуска Вашего спортсбука и поможет
                  добиться успеха в мире гемблинг-индустрии.

                  Особое внимание мы уделили надежности и безопасности нашей платформы. В ней реализованы
                  механизмы защиты проверки личности игроков для отслеживания мультиаккаунтов и «вилочников»,
                  защита от мошенничества со стороны совершения операций в платёжных системах, ограничение
                  доступов для игроков в качестве меры пресечения запрещенных действий.
                </p>
              </div>
            </div>
          </div>
          <div class="total portf">
            <div class="container">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Итого</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Затраты в цифрах</h2>
              <ul>
                <li class="viewport_animation" data-vp-add-class="animated fadeInUp">
                  <h3>Всего затрачено</h3>
                  <span>~ 20 000 часов</span>
                  <p>8 месяцев разработки<br /></p>
                </li>
                <li class="viewport_animation" data-vp-add-class="animated fadeInRight">
                  <h3>Стоимость</h3>
                  <span>129 800 €</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div><div class="bookfoot"></div>
      <ProjectButtons />
    </div >
  );
};

