import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import styles from './styles.module.css';
import closeBtn from './assets/close.png'
const Cookies = () => {
    useEffect(() => {

        let cookie = document.cookie.split(' ');

        for (var i = 0; i < cookie.length; i++) {
            cookie[i] = cookie[i].replace(';', '');
        }

        if (Object.values(cookie).includes("agrBsn=true") || Object.values(cookie).includes("agrBsn=false")) {
            document.getElementById('cookiesCanvasDiv').style.display = 'none';
        }

        if (!Object.values(cookie).includes('agrBsn=true') && !Object.values(cookie).includes('agrBsn=false')) {
            document.getElementById('policy').removeAttribute('style');
        }

        document.getElementById('agreeHandler').addEventListener('click', (e) => {
            document.getElementById('policy').style.display = 'none';

            let date = new Date(Date.now());
            date.setFullYear(date.getFullYear() + 1);
            date = date.toUTCString();
            document.cookie = 'agrBsn=true; expires=' + date + '; path=/';
            document.cookie = 'cs=fc:1,ad:1,an:1; expires=' + date + '; path=/';

            document.getElementById('cookiesCanvas').style.display = 'none';
        });

        document.getElementById('disagreeHandler').addEventListener('click', (e) => {
            document.getElementById('policy').style.display = 'none';

            let date = new Date(Date.now());
            date.setFullYear(date.getFullYear() + 1);
            date = date.toUTCString();
            document.cookie = 'agrBsn=false; expires=' + date + '; path=/';
            document.cookie = 'cs=fc:0,ad:0,an:0; expires=' + date + '; path=/';

            document.getElementById('cookiesCanvas').style.display = 'none';
        });

        document.getElementById('cookieOp').addEventListener('click', (e) => {
            let elem = document.getElementById('cookiesCanvas');
            elem.style.display = 'block';
        });

        document.getElementById('cookieOp').addEventListener('click', (e) => {
            let elem = document.getElementById('cookiesCanvas');
            elem.style.display = 'block';

            document.body.style.overflow = 'hidden';
        });


        document.getElementById('closeBtn').addEventListener('click', (e) => {
            let elem = document.getElementById('cookiesCanvas');
            elem.style.display = 'none';

            document.body.style.overflow = 'auto';
        });

        document.getElementById('confirmCokiesBtn').addEventListener('click', (e) => {
            let sw2 = document.getElementById('switcher2');
            let sw3 = document.getElementById('switcher3');
            let sw4 = document.getElementById('switcher4');

            document.body.style.overflow = 'auto';

            if (sw2.checked && sw3.checked && sw4.checked) {
                let date = new Date(Date.now());
                date.setFullYear(date.getFullYear() + 1);
                date = date.toUTCString();
                document.cookie = 'agrBsn=true; expires=' + date + '; path=/';
                document.cookie = 'cs=fc:1,ad:1,an:1; expires=' + date + '; path=/';

                let elem = document.getElementById('cookiesCanvas');
                elem.style.display = 'none';

                document.getElementById('policy').style.display = 'none';
            }
            else if (sw2.checked && sw3.checked) {
                let date = new Date(Date.now());
                date.setFullYear(date.getFullYear() + 1);
                date = date.toUTCString();
                document.cookie = 'agrBsn=false; expires=' + date + '; path=/';
                document.cookie = 'cs=fc:0,ad:1,an:1; expires=' + date + '; path=/';

                let elem = document.getElementById('cookiesCanvas');
                elem.style.display = 'none';

                document.getElementById('policy').style.display = 'none';
            }
            else if (sw3.checked && sw4.checked) {
                let date = new Date(Date.now());
                date.setFullYear(date.getFullYear() + 1);
                date = date.toUTCString();
                document.cookie = 'agrBsn=false; expires=' + date + '; path=/';
                document.cookie = 'cs=fc:1,ad:0,an:1; expires=' + date + '; path=/';

                let elem = document.getElementById('cookiesCanvas');
                elem.style.display = 'none';

                document.getElementById('policy').style.display = 'none';
            }
            else if (sw2.checked && sw4.checked) {
                let date = new Date(Date.now());
                date.setFullYear(date.getFullYear() + 1);
                date = date.toUTCString();
                document.cookie = 'agrBsn=false; expires=' + date + '; path=/';
                document.cookie = 'cs=fc:1,ad:1,an:0; expires=' + date + '; path=/';

                let elem = document.getElementById('cookiesCanvas');
                elem.style.display = 'none';

                document.getElementById('policy').style.display = 'none';
            }
            else if (sw3.checked) {
                let date = new Date(Date.now());
                date.setFullYear(date.getFullYear() + 1);
                date = date.toUTCString();
                document.cookie = 'agrBsn=false; expires=' + date + '; path=/';
                document.cookie = 'cs=fc:0,ad:0,an:1; expires=' + date + '; path=/';

                let elem = document.getElementById('cookiesCanvas');
                elem.style.display = 'none';

                document.getElementById('policy').style.display = 'none';
            }
            else if (sw2.checked) {
                let date = new Date(Date.now());
                date.setFullYear(date.getFullYear() + 1);
                date = date.toUTCString();
                document.cookie = 'agrBsn=false; expires=' + date + '; path=/';
                document.cookie = 'cs=fc:0,ad:1,an:0; expires=' + date + '; path=/';

                let elem = document.getElementById('cookiesCanvas');
                elem.style.display = 'none';

                document.getElementById('policy').style.display = 'none';
            }
            else if (sw4.checked) {
                let date = new Date(Date.now());
                date.setFullYear(date.getFullYear() + 1);
                date = date.toUTCString();
                document.cookie = 'agrBsn=false; expires=' + date + '; path=/';
                document.cookie = 'cs=fc:1,ad:0,an:0; expires=' + date + '; path=/';

                let elem = document.getElementById('cookiesCanvas');
                elem.style.display = 'none';

                document.getElementById('policy').style.display = 'none';
            }
            else {
                let date = new Date(Date.now());
                date.setFullYear(date.getFullYear() + 1);
                date = date.toUTCString();
                document.cookie = 'agrBsn=false; expires=' + date + '; path=/';
                document.cookie = 'cs=fc:0,ad:0,an:0; expires=' + date + '; path=/';

                let elem = document.getElementById('cookiesCanvas');
                elem.style.display = 'none';

                document.getElementById('policy').style.display = 'none';
            }

        });

    }, []);

    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(true);
    const [isChecked3, setIsChecked3] = useState(true);

    const handleChange1 = (event) => {
        setIsChecked1(event.target.checked);
    };
    const handleChange2 = (event) => {
        setIsChecked2(event.target.checked);
    };
    const handleChange3 = (event) => {
        setIsChecked3(event.target.checked);
    };
    return (
        <div id="cookiesCanvasDiv">
            <noindex className={styles.cookies_settings_canvas} id="cookiesCanvas">
                <cookies className={styles.cookies_settings}>
                    <div className={styles.cookies_settings_container}>
                        <span className={styles.cookies_settings_header} data-lang="cookies-settings-title"
                        >Настройки cookies</span
                        >
                        <img
                            src={closeBtn}
                            alt="close"
                            id="closeBtn"
                            className={styles.close_btn}
                        />
                        <div className={styles.cookies_settings_pop_up}>
                            <div>
                                <div id="coockiesS1">
                                    <p
                                        className={styles.cookies_settings_link}
                                        data-lang={styles.cookies_settings_list1}
                                    >
                                        Обязательные cookies
                                    </p>
                                </div>
                                <label className={styles.switch_checked}>
                                    <input
                                        type="checkbox"
                                        className={styles.switch_input_checked}
                                        id="switcher1"
                                        defaultChecked={true}
                                        disabled
                                    />
                                    <span className={styles.slider_btn_checked}></span>
                                </label>
                                <span className={styles.cookies_settings_sub_link}
                                >Эти cookies необходимы для предоставления ключевых услуг и
                                    функций, таких как авторизация, поддержание работы сайта и
                                    обеспечение его безопасности. Отключение невозможно.</span
                                >
                            </div>
                            <hr />
                            <div>
                                <div id="coockiesS4">
                                    <p
                                        className={styles.cookies_settings_link}
                                        data-lang="cookies-settings-list4"
                                    >
                                        Функциональные cookies
                                    </p>
                                </div>
                                <label className={styles.switch}>
                                    <input
                                        type="checkbox"
                                        className={styles.switch_input}
                                        id="switcher4"
                                        checked={isChecked1}
                                        onChange={handleChange1}
                                    />
                                    <span className={styles.slider_btn}></span>
                                </label>
                                <span
                                    className={styles.cookies_settings_sub_link}
                                    data-lang="cookies-settings-list-text4"
                                >Используются для запоминания информации о посетителях сайта,
                                    например, языка и расширенного контента.</span
                                >
                            </div>
                            <hr />
                            <div>
                                <div id="coockiesS2">
                                    <p
                                        className={styles.cookies_settings_link}
                                        data-lang="cookies-settings-list2"
                                    >
                                        Рекламные cookies
                                    </p>
                                </div>
                                <label className={styles.switch}>
                                    <input
                                        type="checkbox"
                                        className={styles.switch_input}
                                        id="switcher2"
                                        checked={isChecked2}
                                        onChange={handleChange2}
                                    />
                                    <span className={styles.slider_btn}></span>
                                </label>
                                <span
                                    className={styles.cookies_settings_sub_link}
                                    data-lang="cookies-settings-list-text1"
                                >Используются для показа посетителям релевантной рекламы и
                                    маркетинговых кампаний.</span
                                >
                            </div>
                            <hr />
                            <div>
                                <div id="coockiesS3">
                                    <p
                                        className={styles.cookies_settings_link}
                                        data-lang="cookies-settings-list3"
                                    >
                                        Аналитические cookies
                                    </p>
                                </div>
                                <label className={styles.switch}>
                                    <input
                                        type="checkbox"
                                        className={styles.switch_input}
                                        id="switcher3"
                                        checked={isChecked3}
                                        onChange={handleChange3}
                                    />
                                    <span className={styles.slider_btn}></span>
                                </label>
                                <span
                                    className={styles.cookies_settings_sub_link}
                                    data-lang="cookies-settings-list-text2"
                                >Необходимы для статистических целей и помогают улучшать работу
                                    нашего сайта.</span
                                >
                            </div>
                        </div>
                        <interactiveButton
                            button-type=" button"
                            className={[styles.small, styles.interactive_button, styles.cookies_settings_btn, styles.save_btn]}
                            data-accept=""
                        >
                            <button type="button" id="confirmCokiesBtn" style={{ width: 'auto', marginTop: 20 }} className={styles.cbtn2}>
                                <i data-lang="cookies-settings-confirm-btn"> Сохранить </i>
                            </button>
                        </interactiveButton>
                    </div>
                </cookies>
            </noindex>

            <noindex id="policy" style={{ visibility: 'hidden' }}>
                <acceptCookies className={styles.accept_cookies} id="policys">
                    <div className={styles.accept_cookies_text}>
                        <p data-lang="cookies-text" id="cst">
                            Мы используем cookies для быстрой и удобной работы сайта. Вы можете
                            настроить необязательные cookies
                            <a id="cookieOp"> здесь</a>. Подробнее о обработке cookies см. в <a
                                href="/privacy-policy/"
                                className=""
                                target="_blank"
                            >политике конфиденциальности</a
                            >.
                        </p>

                        <interactiveButton
                            button-type="button"
                            className={[styles.small, styles.interactive_button]}
                            data-accept=""
                        >
                            <button type="button" id='disagreeHandler' className={styles.cbtn1}>
                                <i data-lang="cookies-decline-btn"> Отклонить </i>
                            </button>
                            <button type="button" id='agreeHandler' className={styles.cbtn2}>
                                <i data-lang="cookies-accept-btn"> Принять все cookies </i>
                            </button>
                        </interactiveButton>
                    </div>
                </acceptCookies>
            </noindex>
        </div>
    );
};

export default Cookies;
