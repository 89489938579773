import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { NameInput, ContactInput, AboutInput } from './components';

import styles from './styles.module.scss';

export const OrderForm = ({ closeModal, type, forContactsFrame }) => {
  const { t } = useTranslation();
  const [stateForm, setStateForm] = useState({
    name: '',
    conctact: '',
    about: '',
  });

  const onNameChange = (value) => {
    setStateForm((prev) => ({
      ...prev,
      name: value,
    }));
  };

  const onContactChange = (value) => {
    setStateForm((prev) => ({
      ...prev,
      conctact: value,
    }));
  };

  const onAboutChange = (value) => {
    setStateForm((prev) => ({
      ...prev,
      about: value,
    }));
  };

  closeModal = (e) => {
    var xhr = new XMLHttpRequest();

    xhr.addEventListener('lod', () => {
      // console.log(xhr.responseText);
    });



    xhr.open('GET', 'https://1ec9cd71c525.hosting.myjino.ru/mail.php?name=' + stateForm.name +
      '&contact=' + stateForm.conctact +
      '&about=' + stateForm.about);

    xhr.send();

    e.preventDefault();

    function reload_page() {
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }

    reload_page();

  }
  return (
    <form
      onSubmit={closeModal}
      className={cn(styles.orderForm, {
        [styles.orderFormForContactFrame]: forContactsFrame,
      })}
    >
      {!forContactsFrame && (
        <h2 className={styles.orderForm_title}>{t(`${type}.title`)}</h2>
      )}
      <p
        className={cn(styles.orderForm_description, {
          [styles.orderForm_descriptionOrange]: forContactsFrame,
        })}
      >
        {t(`${type}.description`)}
      </p>
      <NameInput
        value={stateForm.name}
        change={onNameChange}
        placeholderText={t(`${type}.placeholderName`)}
      />
      <ContactInput
        value={stateForm.conctact}
        change={onContactChange}
        placeholderText={t(`${type}.placeholderContact`)}
      />
      <AboutInput
        value={stateForm.about}
        change={onAboutChange}
        className={styles.aboutInput}
        placeholderText={t(`${type}.placeholderProject`)}
      />
      <p className={styles.orderForm_agreement}>{t(`${type}.agreement`)}</p>
      <button className={styles.orderForm_submitBtn} type="submit">
        {t(`${type}.submitBtn`)}
      </button>
    </form>
  );
};
