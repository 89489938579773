import React, { useEffect } from 'react';
import { ProjectButtons } from '../../ProjectButtons';
import styles from './styles.module.scss';
import Steps from './step.js';

export const Finbet = () => {
  useEffect(() => {
    import('../assets/js/additional.min.js')
      .then(() => import('../assets/js/main.min.js')
        .then(() => import('../assets/js/finbet.min.js')))
  }, [])

  return (
    <div id="portf">
      <div class="content">
        <div class="portfolio-slider">
          <div class="item">
            <img src="/img/background/finbet.png" alt="" />
            <div class="inner-text">
              <div class="border">
                <img class=" viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/finbet.png" alt="Конфигуратор" />
                <h1 class=" viewport_animation" style={{ marginTop: '0' }} data-vp-add-class="animated fadeInRight">
                  Финансовые ставки</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="portfolio" id="portfolio">
          <div class="conf-screen">
            <img class="left viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/finbet/screen_2.png" alt="Левый" />
            <img class="center viewport_animation" data-vp-add-class="animated fadeInUp" src="/img/finbet/screen_1.png" alt="Центральный" />
            <img class="right viewport_animation" data-vp-add-class="animated fadeInRight" src="/img/finbet/screen_3.png" alt="Правый" />
          </div>
          <div class="task">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Концепция
                проекта</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Finbet - площадка для онлайн-торгов
                на валютной бирже</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Немногие знакомы с понятием
                финансового беттинга, но сегодня - это новый этап в сфере ставок и способов заработка. Модуль
                предусматривает прогнозирование стоимости одной валюты к другой. Finbet будет интересен не только
                финансовым брокерам, но и гэмблинг-бизнесу для расширения охвата потенциальных беттеров.</p>
            </div>
          </div>
          <div class="finbet-steps -gray inf-cnt">
            <div class="container">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Этапы работы над
                проектом</span>
            </div>
            <div class="steps viewport_animation" data-vp-add-class="animated fadeInUp">
              <Steps />
            </div>
          </div>
          <div class="finbet-tech">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Техническое
                задание</span>
              <div class="inner-text">
                <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Составление технического
                  задания на этапе планирования</h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Вместе с заказчиком наша команда
                  составила техническое задание, которое помогло найти различные “подводные камни” на этапе
                  согласования.

                  Большой объём работы был проделан по изучению задач платформы и её конкурентов.

                  В результате была разработана схема работы Finbet, которая невероятна проста для обычного
                  пользователя:

                  пользователь выбирает актив для ставки (например, курс валют USD/EUR);

                  определяет срок действия опциона (час, день и т.п.);

                  выбирает свой прогноз - повышение или понижение значения;

                  если прогноз верен - пользователь выигрывает ставку; при неверном прогнозе - ставка проиграна.
                </p>
              </div>
              <div class="paper">
                <img src="/img/finbet/list_1.png" alt="стопка" class="list_1 viewport_animation" data-vp-add-class="animated fadeInDown" />
                <img src="/img/finbet/list_2.png" alt="второй лист" class="list_2 viewport_animation" data-vp-add-class="animated fadeInDown" />
                <img src="/img/finbet/list_3.png" alt="первый лист" class="list_3 viewport_animation" data-vp-add-class="animated fadeInDown" />
              </div>
            </div>
          </div>
          <div class="finbet-api -gray">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Резюме</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Преимущества решения</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Преимущества для бизнеса в том, что
                на каждую ставку задаётся маржа - доля брокера в ставке. Потому Finbet обладает не только простотой
                в использовании, но и высоким потенциалом прибыли. Также для администратора в модуле разработана
                своя рабочая панель.

                Большим плюсом является то, что модуль оперативно и непрерывно получает данные о курсе валют, что
                обеспечивает работу без сбоев 24/7 и оставляет приятный опыт использования у беттеров.
              </p>
              <div class="browser-api viewport_animation" data-vp-add-class="animated fadeInLeft">
                <div class="currency" id="currency">
                  <div class="row -down">
                    <p>AUD / USD</p>
                    <span>1.177318</span>
                  </div>
                  <div class="row -down">
                    <p>NZD / USD</p>
                    <span>112.7307</span>
                  </div>
                  <div class="row -up">
                    <p>GPB / JPY</p>
                    <span>1.341624</span>
                  </div>
                  <p>При разработке Finbet наша команда учла тот факт, что модуль будет интегрироваться на
                    сторонние проекты. Именно поэтому Finbet легко установить и настроить на любой площадке.</p>
                </div>
                <div class="browser">
                  <div class="outer">
                    <img src="/img/finbet/screen_1.png" alt="" />
                  </div>
                </div>
              </div>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">API и интерграция с другими
                системами</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Работая над данным порталом и
                функционалом в частности, мы заложили возможность дальнейшего применения созданных модулей на
                сторонних проектах. Таким образом, весь объём проделанной работы стал своего рода легко
                устанавливаемым дополнением, которое можно внедрить практически на любую платформу.<br />
                <br />
                Благодаря реализации с учётом дальнейшего внедрения функционала на другие схожие проекты, мы смогли
                подготовить удобный инструмент не только для пользователей сервиса, но и для будущих разработчиков,
                которым может предстоять внедрение модуля на другие площадки.
              </p>
            </div>
          </div>
          <div class="total">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Итого</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Ресурсы разработки в цифрах</h2>
              <ul>
                <li class="viewport_animation" data-vp-add-class="animated fadeInUp">
                  <h3>Всего затрачено</h3>
                  <span>1 500 часов</span>
                  <p>Тех. задание 30 часов<br />
                    Прототипирование 40 часов<br />
                    Дизайн страниц 80 часов<br />
                    Фронтенд 483 часов<br />
                    Бэкенд 817 часов<br />
                    Тестирование 50 часов</p>
                </li>
                <li class="viewport_animation" data-vp-add-class="animated fadeInRight">
                  <h3>Примерная стоимость проекта на сегодняшний день</h3>
                  <span>36 000 €</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ProjectButtons />
    </div>
  );
};
