import React, { useEffect } from 'react';
import { ProjectButtons } from '../../ProjectButtons';

export const Fincrm = () => {
  useEffect(() => {
    import('../assets/js/additional.min.js')
      .then(() => import('../assets/js/main.min.js')
        .then(() => import('../assets/js/finempire.min.js')))
  }, [])



  return (
    <div>
      <canvas id="maxline-chart" height="400" style={{ display: 'none' }}></canvas>
      <div class="content">
        <div class="portfolio-slider">
          <div class="item">
            <img src="/img/background/finempire.png" alt="" />
            <div class="inner-text">
              <div class="border">
                <img class=" viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/finance.png" alt="Конфигуратор" />
                <h1 class=" viewport_animation" data-vp-add-class="animated fadeInRight">Finance CRM</h1>
                <p class=" viewport_animation" data-vp-add-class="animated fadeInRight">Модуль для управления
                  финансовым потоком</p>
              </div>
            </div>
          </div>
        </div>
        <div class="portfolio" id="portfolio">
          <div class="conf-screen">
            <img class="left viewport_animation" data-vp-add-class="animated fadeInLeft" src="/img/finempire/screen_2.png" alt="Левый" />
            <img class="center viewport_animation" data-vp-add-class="animated fadeInUp" src="/img/finempire/screen_1.png" alt="Центральный" />
            <img class="right viewport_animation" data-vp-add-class="animated fadeInRight" src="/img/finempire/screen_3.png" alt="Правый" />
          </div>
          <div class="task ">
            <div class="container inf__cnt-wpt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Концепция
                проекта</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Finance CRM - невероятно простой и
                удобный инструмент для контроля финансового потока, который позволяет раз и навсегда избавить
                менеджеров от сложных расчетов при оценке эффективности бизнеса.</h2>
              <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Finance CRM универсальна, поэтому
                подойдёт для любой организации, которая работает со множеством юридических лиц либо задействована на
                множестве проектов.

                С данным модулем нет необходимости набирать дополнительный штат бухгалтерии - всю динамику Finance
                CRM сформирует и отобразит в виде наглядных графиков и диаграмм.
              </p>
            </div>
          </div>
          <div class="crm-block -gray conFin">
            <div class="screen">
              <img src="/img/finempire/scr_1.png" alt="Список проектов FinCRM" class="first viewport_animation" data-vp-add-class="animated fadeInRight" />
              <img src="/img/finempire/scr_2.png" alt="Главная страница FinCRM" class="second viewport_animation" data-vp-add-class="animated fadeInRight" />
            </div>
            <div class="container inf-cnt">
              <div class="inner-text">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  —
                  Возможности</span>
                <h2 class="viewport_animation fne-tl__ps" data-vp-add-class="animated fadeInUp">Основные функции Finance CRM:
                </h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Контроль дебиторской
                  задолженности;

                  Планирование денежных поступлений;

                  Учёт текущих и будущих расходов;

                  Ведение клиентской базы с возможностью создания учётных записей клиентов;

                  Привязка проектов к клиентам;

                  Формирование счетов;

                  Отслеживание статусов проектов.
                </p>
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="crm-block">
            <div class="container crm_slider inf-cnt">
              <div class="inner-text">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  — Контроль
                  расходов</span>
                <h2 class="viewport_animation" data-vp-add-class="animated fadeInUp">Детализация данных в диаграммах
                  и графиках</h2>
                <p class="viewport_animation active_charts" data-vp-add-class="animated fadeInUp">Помимо всего
                  прочего руководство компании приобретает в лице Finance CRM возможность формирования отчётов по
                  прибыльности. На примере среза по доходам и расходам можно проследить тенденцию и сделать выводы
                  о текущим положении дел в бизнесе. Для удобства восприятия отчёты формируются в виде графиков и
                  диаграмм. Главные преимущества здесь:

                  точность расчётов - система их выполняет автоматически, исключая ошибки человеческого фактора;

                  скорость получения результата - получить отчёт можно за пару кликов, не тратя своё время на
                  самостоятельные подсчёты.
                </p>
              </div>
              <div class="owl-carousel" id="crm_slider">
                <div class="item">
                  <div class="chart">
                    <canvas id="graph_bar_group"></canvas>
                  </div>
                </div>
                <div class="item">
                  <div class="chart">
                    <canvas id="rounded_chart_group"></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="crm-block -gray">
            <div style={{ paddingBottom: '0' }} class="container">
              <div class="inner-text">
                <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                  — Готовый
                  инструмент</span>
                <h2 class="viewport_animation fne-tl__ps" data-vp-add-class="animated fadeInUp">Система управления финансами не
                  требует сложных настроек и проста в использовании.</h2>
                <p class="viewport_animation" data-vp-add-class="animated fadeInUp">Ключевыми преимуществами данной
                  системы управления финансами являются простота её использования, и отсутствие сложных настроек,
                  как при установке, так и при работе с ней. Приобретая лицензию на использование этого продукта,
                  вы получаете полностью готовый к работе инструмент, который будет служить вам надёжным
                  помощником и проводником среди финансовых потоков вашей компании. Установка и настройка продукта
                  займёт у вас не более тридцати минут. Быстро, просто и оперативно – именно так можно
                  характеризовать процесс установки и настройки нашего продукта. Кроме того, мы регулярно улучшаем
                  данную систему управления. Вместе с лицензией вы получаете безграничный пакет обновлений, на
                  период действия лицензии. Контроль актов, счетов, доходов, расходов и прибыли в целом – всё это
                  реализовано в данной системе.</p>
              </div>
              <div class="screen-box">
                <img src="/img/finempire/bg_box.png" alt="background box" class="background viewport_animation" data-vp-add-class="animated fadeInRight" />
                <img src="/img/finempire/box.png" alt="Box FinCRM" class="box viewport_animation" data-vp-add-class="animated fadeInRight" />
              </div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="total">
            <div class="container inf-cnt">
              <span class="step-name viewport_animation" data-vp-add-class="animated fadeInLeft">
                — Итого</span>
              <h2 class="viewport_animation" data-vp-add-class="animated fadeInLeft">Ресурсы разработки в цифрах</h2>
              <ul>
                <li class="viewport_animation" data-vp-add-class="animated fadeInUp">
                  <h3>Всего затрачено</h3>
                  <span>1 000 часов</span>
                  <p>Тех. задание 40 часов<br />
                    Прототипирование 40 часов<br />
                    Дизайн страниц 60 часов<br />
                    Фронтенд 270 часов<br />
                    Бэкенд 510 часов<br />
                    Тестирование 80 часов</p>
                </li>
                <li class="viewport_animation" data-vp-add-class="animated fadeInRight">
                  <h3>Примерная стоимость проекта на сегодняшний день</h3>
                  <span>25 000 €</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ProjectButtons />
    </div>
  );
};
