export const TEAM_LIST = [
  {
    id: 0,
    photo: '/images/about/avatar.png',
    name: 'Дмитрий Шаринский',
    post: 'CEO',
    description:
      'Мы разрабатываем инновационные решения и помогаем нашим клиентам использовать современные технологии для развития своего бизнеса. Мы имеем более чем десятилетний опыт разработки сложного и высоконагруженного программного обеспечения. Наша команда состоит из талантливых специалистов, которые всегда готовы помочь вам достичь успеха. Мы готовы выполнить проект любой сложности, при этом гарантируем высокое качество и скорость оказываемых услуг.',
  },
  {
    id: 1,
    photo: '/images/about/avatar.png',
    name: 'Дмитрий Шаринский',
    post: 'CEO',
    description:
      'Мы разрабатываем инновационные решения и помогаем нашим клиентам использовать современные технологии для развития своего бизнеса. Мы имеем более чем десятилетний опыт разработки сложного и высоконагруженного программного обеспечения. Наша команда состоит из талантливых специалистов, которые всегда готовы помочь вам достичь успеха. Мы готовы выполнить проект любой сложности, при этом гарантируем высокое качество и скорость оказываемых услуг.',
  },
  {
    id: 2,
    photo: '/images/about/avatar.png',
    name: 'Дмитрий Шаринский',
    post: 'CEO',
    description:
      'Мы разрабатываем инновационные решения и помогаем нашим клиентам использовать современные технологии для развития своего бизнеса. Мы имеем более чем десятилетний опыт разработки сложного и высоконагруженного программного обеспечения. Наша команда состоит из талантливых специалистов, которые всегда готовы помочь вам достичь успеха. Мы готовы выполнить проект любой сложности, при этом гарантируем высокое качество и скорость оказываемых услуг.',
  },
  {
    id: 3,
    photo: '/images/about/avatar.png',
    name: 'Дмитрий Шаринский',
    post: 'CEO',
    description:
      'Мы разрабатываем инновационные решения и помогаем нашим клиентам использовать современные технологии для развития своего бизнеса. Мы имеем более чем десятилетний опыт разработки сложного и высоконагруженного программного обеспечения. Наша команда состоит из талантливых специалистов, которые всегда готовы помочь вам достичь успеха. Мы готовы выполнить проект любой сложности, при этом гарантируем высокое качество и скорость оказываемых услуг.',
  },
  {
    id: 4,
    photo: '/images/about/avatar.png',
    name: 'Дмитрий Шаринский',
    post: 'CEO',
    description:
      'Мы разрабатываем инновационные решения и помогаем нашим клиентам использовать современные технологии для развития своего бизнеса. Мы имеем более чем десятилетний опыт разработки сложного и высоконагруженного программного обеспечения. Наша команда состоит из талантливых специалистов, которые всегда готовы помочь вам достичь успеха. Мы готовы выполнить проект любой сложности, при этом гарантируем высокое качество и скорость оказываемых услуг.',
  },
];
